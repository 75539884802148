import React from "react";

import "./SingleBlogPage.scss";


import HeaderUI from "../../components/ui/HeaderUI/HeaderUI";
import Footer from "../../components/ui/Footer/Footer";

import arrowLeft from "../../assets/arrow-left.svg"


import { Link } from "react-router-dom";

const SingleBlogPage = ({data}) => {

    return (
        <>
            <HeaderUI />
            <div className="singleBlog">
                <Link className="mainBlock__link" to="/blogs"><img src={arrowLeft} alt="arrow left" />Все блоги</Link>
                <div className="singleBlog__title">
                    {data.title}
                </div>
                <div className="singleBlog__date">
                    {data.date}
                </div>
                <div className="singleBlog__content">
                    {data.text}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SingleBlogPage;