import React from 'react'

import rightArrow from "../../../assets/arrow-right.svg"

import "./BlogBlock.scss"

const BlogBlock = ({data}) => {
  return (
    <div className='blogBlock'>
      <div className='blogBlock__title'>
        {data.title}
      </div>
      <div className='blogBlockContent'>
        <div className='blogBlockContentWrapper'>
          {/* <div className='blogBlock__date'>{data.date}</div>         */}
            { data.tags ?
              data.tags.map((tag) => {
                return (
                  <div className='blogBlock__tag' key={tag.id}>
                    {tag.text}
                  </div>
                );
              }) : ""
            }
        </div>
        <a href={"/blogs/" + data.id} className='blogBlock__link'>Читать полностью <img className='blogBlock__link__img' src={rightArrow} alt="arrow-right" /></a>
      </div>
    </div>
  )
}

export default BlogBlock