const blogData = [
    {
        id: "blog1",
        title: "Аренда спецтехники с экипажем и без",
        date: "05.10.2024",
        text: (<>
        <p>
          <span style={{ fontWeight: 400 }}>
            Аренда спецтехники может быть двух видов: с экипажем и без.
          </span>
        </p>
        <p>
          <strong>Аренда с экипажем</strong>
          <span style={{ fontWeight: 400 }}>
            {" "}
            включает в себя предоставление техники вместе с квалифицированными
            операторами. Это идеальный вариант для компаний, не имеющих в своем штате
            специалистов по управлению такой техникой. Преимущества:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Отсутствие необходимости нанимать и обучать персонал.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Снижение риска повреждения техники.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Высокая эффективность и качество работ.
            </span>
          </li>
        </ul>
        <p>
          <strong>Аренда без экипажа</strong>
          <span style={{ fontWeight: 400 }}>
            {" "}
            предполагает самостоятельное управление арендатором. Это подходит для
            компаний с опытными операторами. Преимущества:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Снижение стоимости аренды.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Гибкость в использовании техники по графику компании.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Возможность использования собственных операторов, знакомых с проектом.
            </span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            При выборе между этими вариантами учитывайте доступность квалифицированных
            операторов, сложность работ, бюджет и срок проекта.
          </span>
        </p>
        <h4>
          <strong>Основные виды спецтехники</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            На рынке доступно множество видов спецтехники, которую можно арендовать:
          </span>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <strong>Строительная спецтехника</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Экскаваторы, бульдозеры, краны, строительные миксеры, бетономешалки.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Применяются для земляных работ, строительства и демонтажа зданий.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Подъемная спецтехника</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Автовышки, автокраны, манипуляторы.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Используются для подъема и перемещения грузов на высоте.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Дорожная техника</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Асфальтоукладчики, катки, дорожные фрезы.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Применяются для строительства и ремонта дорог.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Коммунальная спецтехника</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Снегоуборочные машины, тракторы, мусоровозы.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Используются для обслуживания дорог и территории.
              </span>
            </li>
          </ul>
        </ol>
        <h4>
          <strong>Виды аренды спецтехники</strong>
        </h4>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <strong>Аренда без водителя</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Экономичный вариант.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Арендатор предоставляет водителя и несет ответственность за технику.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Аренда с экипажем</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Подходит для сложной техники.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Арендодатель предоставляет квалифицированного оператора.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Аренда навесного оборудования</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Предоставление техники с дополнительным оборудованием.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Заключение стандартного договора аренды.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Подрядные отношения</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Выполнение определенного объема работ «под ключ».
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Подрядчик отвечает за персонал и технику.
              </span>
            </li>
          </ul>
        </ol>
        <h4>
          <strong>Аренда спецтехники в Москве</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            Аренда спецтехники становится все более популярной услугой. Основные виды
            аренды включают технику с экипажем и без. При выборе учитывайте тип работ,
            опыт операторов, состояние техники и сравнивайте цены от различных
            поставщиков. Грамотный подход обеспечит успешное и эффективное выполнение
            работ.
          </span>
        </p>
        <h4>
          <strong>Виды техники для строительства и коммунальных работ</strong>
        </h4>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <strong>Экскаваторы</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Колесные, гусеничные, фронтальные и экскаваторы-погрузчики.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Используются для земляных работ, копания котлованов, траншей.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Автокраны</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Мобильные и стационарные.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Применяются для подъема и перемещения тяжелых грузов.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Самосвалы</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Различаются по грузоподъемности и объему кузова.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Используются для транспортировки строительных материалов и отходов.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Бульдозеры</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Применяются для планировки территории, расчистки участков.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Погрузчики</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Фронтальные, телескопические и боковые.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Играют ключевую роль в перемещении и складировании материалов.
              </span>
            </li>
          </ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Дорожная техника</strong>
            <span style={{ fontWeight: 400 }}>:</span>
          </li>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Асфальтоукладчики, катки, дорожные фрезы.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Применяются для строительства и ремонта дорог.
              </span>
            </li>
          </ul>
        </ol>
      </>)
      ,
    },
    {
        id: "blog2",
        title: "Благоустройство территории",
        date: "05.10.2024",
        text: (<>
        <p>
          <span style={{ fontWeight: 400 }}>
            Благоустройство участка: Выравнивание земли перед строительством
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>Зачем нужно выравнивание участка?</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Перед началом строительства дома или промышленного строения необходимо
            выровнять участок, чтобы обеспечить ровную поверхность для строительства.
            Это важный этап подготовки, который включает в себя несколько ключевых
            шагов.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>Этапы выравнивания земли</span>
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Определение назначения зон выравнивания: Разделение участка на зоны,
              которые требуют выравнивания, в зависимости от планируемого
              использования.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> Детальный анализ почвы:&nbsp;</span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            Исследование состава почвы, чтобы понять ее характеристики и определить,
            какие методы выравнивания будут наиболее эффективными.
          </span>
        </p>
        <ol start={3}>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Проверка наличия и расположения внутренних вод:
            </span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            &nbsp;Учет расположения подземных вод для предотвращения возможных проблем
            с дренажем и устойчивостью грунта.
          </span>
        </p>
        <ol start={4}>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Определение стока и формы рельефа:
            </span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            &nbsp;Оценка естественного стока воды и особенностей рельефа для
            правильного планирования выравнивания и предотвращения эрозии.
          </span>
        </p>
        <ol start={5}>
          <li>
            <span style={{ fontWeight: 400 }}> Процедура выравнивания:&nbsp;</span>
          </li>
        </ol>
        <p>
          <span style={{ fontWeight: 400 }}>
            Перенос и подсыпка земли для достижения ровной поверхности. Важно
            сохранять верхний слой почвы, чтобы сохранить полезные микроорганизмы.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>Важные моменты</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            - Хранение почвы: Верхний слой почвы может храниться только 3,5 недели.
            После этого полезные микроорганизмы внутри начинают погибать, что может
            негативно сказаться на качестве земли.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>- Связь с менеджером:</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            &nbsp;Для заказа услуги на нашем сайте необходимо оставить заявку или
            позвонить. Убедитесь, что ваш смартфон работает, чтобы наш менеджер мог до
            вас дозвониться.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>Как заказать услугу?</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Вы можете заказать услугу выравнивания участка на нашем сайте, оставив
            заявку или связавшись с нашими специалистами по телефону. Если у вас
            остались вопросы по поводу данной услуги, наши специалисты будут рады
            помочь вам и предоставить всю необходимую информацию.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>Контактная информация</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>внести данные&nbsp;</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>- Сайт: [Ваш сайт]</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>- Телефон: [Ваш телефон]</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>- Электронная почта: [Ваш email]</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Мы гарантируем качественное выполнение всех этапов выравнивания земли и
            готовы помочь вам в подготовке участка для строительства вашего дома или
            промышленного объекта.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Перед началом строительства необходимо подготовить участок. Основные
            этапы:
          </span>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Определение назначения зон выравнивания.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Анализ почвы и проверка водных ресурсов.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Определение стока и формы рельефа.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Перенос и подсыпка земли.</span>
          </li>
        </ul>
        <p>
          <span style={{ fontWeight: 400 }}>
            Заказывайте услуги по благоустройству на нашем сайте.
          </span>
        </p>
      </>)
      ,
    },
    {
        id: "blog3",
        title: "Вывоз мусора контейнерами",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз мусора контейнерами — удобный и популярный способ утилизации.
                Контейнеры бывают разных типов и размеров. Процесс включает:
              </span>
            </p>
            <ol>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Выбор подходящего контейнера.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Оформление документов.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Установка контейнера.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Сбор и транспортировка мусора.</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Мы предлагаем контейнеры различных объемов для любой задачи. Соблюдайте
                правила техники безопасности при работе с мусором.
              </span>
            </p>
            <h4>
              <strong>Жалобы на несвоевременный вывоз мусора</strong>
            </h4>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если мусор не вывозят вовремя, это ухудшает санитарную обстановку и
                нарушает законодательство. Обратитесь в одну из организаций:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Управляющая компания.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Региональный оператор.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Государственные органы (Роспотребнадзор, Министерство ЖКХ, жилищная
                  инспекция).
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                Укажите в жалобе адрес, дату последнего вывоза и приложите фото и видео
                подтверждения.
              </span>
            </p>
            <h4>
              <strong>Ответственность за выброс мусора в чужой контейнер</strong>
            </h4>
            <p>
              <span style={{ fontWeight: 400 }}>
                Выбрасывать мусор в чужой контейнер запрещено. За это предусмотрены
                штрафы:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Физические лица: 1 000 - 2 000 рублей.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Юридические лица: 20 000 - 100 000 рублей.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Индивидуальные предприниматели: до 5 000 рублей.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                Чтобы избежать нарушений, изолируйте свои контейнеры.
              </span>
            </p>
            <h4>
              <strong>Снос старых домов и вывоз мусора</strong>
            </h4>
            <p>
              <span style={{ fontWeight: 400 }}>
                Снос старых домов требует качественного вывоза мусора. Наша компания
                предлагает услуги по вывозу строительного мусора с использованием
                спецтехники и контейнеров различного объема. Мы гарантируем оптимальное
                соотношение стоимости и качества услуг.
              </span>
            </p>
            <h4>
              <strong>Услуги компании "СпецУстройство"</strong>
            </h4>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания "СпецУстройство" предоставляет комплексные решения по утилизации
                мусора. Мы работаем с юридическими и частными клиентами, предлагаем
                контейнеры различных объемов и соблюдаем все требования законодательства.
                Наши услуги включают:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Вывоз строительного мусора.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Вывоз крупногабаритных отходов.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Утилизацию бытовых отходов.</span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                Свяжитесь с нашими менеджерами для оформления заказа и получения
                консультации.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog4",
        title: "Важность выравнивания участка перед строительством",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед началом строительства дома или промышленного здания необходимо
                провести тщательное выравнивание участка. Это важно для обеспечения ровной
                поверхности, на которой будет возводиться здание. Заказать эту услугу вы
                можете на нашем сайте, оставив заявку или позвонив нам. Обратите внимание,
                чтобы наш менеджер смог связаться с вами, ваш смартфон должен быть
                включен.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Основные этапы выравнивания участка</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Определение назначения зон выравнивания: Первым шагом является
                  определение, какие зоны участка нуждаются в выравнивании и с какой
                  целью.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}> Детальный анализ почвы:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Анализ почвы помогает понять ее состав и структуру, что важно для выбора
                метода выравнивания.
              </span>
            </p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Проверка нахождения и расположения внутренних вод:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;Важно знать, где проходят подземные воды, чтобы избежать проблем с
                дренажем и фундаментом.
              </span>
            </p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Определение стока и формы рельефа:&nbsp;
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Необходимо учесть естественные уклоны и водостоки для предотвращения
                затоплений.
              </span>
            </p>
            <ol start={5}>
              <li>
                <span style={{ fontWeight: 400 }}> Процедура выравнивания:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Включает в себя перенос и подсыпание земли для достижения нужного уровня.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag4_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag4_2",
                text: "выравнивание участка"
            },
            {
                id: "tag4_3",
                text: "строительство"
            },
            {
                id: "tag4_4",
                text: "подготовка участка"
            },
        ]
    },
    {
        id: "blog4a",
        title: "Почему важно анализировать почву перед выравниванием участка",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Анализ почвы является важным этапом перед началом выравнивания участка для
                строительства. Этот процесс помогает определить свойства почвы, такие как
                ее состав, структуру и влажность, что важно для правильного выбора метода
                выравнивания и использования необходимой спецтехники.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Этапы анализа почвы</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Сбор образцов почвы:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Образцы берутся с разных участков, чтобы получить полное представление о
                состоянии земли.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Лабораторный анализ:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Образцы отправляются в лабораторию, где проводится детальный анализ их
                состава и структуры.
              </span>
            </p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Оценка результатов:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Полученные данные помогают определить, какие методы и материалы лучше
                всего подойдут для выравнивания участка.
              </span>
            </p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> Планирование работ:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                На основе анализа составляется план выравнивания и подбирается необходимая
                техника.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag4a_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag4a_2",
                text: "анализ почвы"
            },
            {
                id: "tag4a_3",
                text: "выравнивание участка"
            },
            {
                id: "tag4a_4",
                text: "подготовка к строительству"
            },
        ]
    },
    {
        id: "blog4b",
        title: "Как избежать проблем с подземными водами при выравнивании участка",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Проблемы с подземными водами могут значительно осложнить процесс
                строительства и привести к дополнительным затратам. Поэтому перед
                выравниванием участка важно провести проверку нахождения и расположения
                подземных вод.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Этапы проверки подземных вод</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Исследование геологических данных:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;Изучение геологических карт и данных помогает определить вероятное
                расположение подземных вод.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Бурение скважин:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;Проведение пробного бурения для точного определения уровня подземных
                вод.
              </span>
            </p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Мониторинг:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наблюдение за уровнем подземных вод в течение определенного времени для
                оценки их сезонных колебаний.
              </span>
            </p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Разработка дренажной системы:&nbsp;
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                При необходимости проектируется и устанавливается система дренажа для
                отвода избыточной влаги.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag4b_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag4a_2",
                text: "подземные воды"
            },
            {
                id: "tag4a_3",
                text: "выравнивание участка"
            },
            {
                id: "tag4a_4",
                text: "подготовка к строительству"
            },
        ]
    },
    {
        id: "blog4c",
        title: "Подготовка участка к строительству: перенос и подсыпка земли",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                После проведения всех предварительных этапов выравнивания участка
                наступает время для основной процедуры - переноса и подсыпки земли. Этот
                процесс включает в себя использование специализированной техники и
                материалов для достижения нужного уровня земли.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Этапы переноса и подсыпки земли</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Перенос земли:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;С помощью экскаваторов и бульдозеров производится перенос излишков
                земли с возвышенных участков на низины.
              </span>
            </p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Подсыпка земли:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                На низменные участки добавляется грунт для выравнивания поверхности.
              </span>
            </p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Уплотнение грунта:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Специальные катки используются для уплотнения подсыпанного грунта, что
                обеспечивает его стабильность.
              </span>
            </p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> Финишное выравнивание:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;После всех работ производится окончательное выравнивание поверхности
                для подготовки к строительству.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Используя наши услуги по аренде спецтехники, вы можете быть уверены в
                качественном и своевременном выполнении всех этапов выравнивания участка.
                Свяжитесь с нами для получения дополнительной информации и заказа услуг.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag4c_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag4c_2",
                text: "перенос земли"
            },
            {
                id: "tag4c_3",
                text: "подсыпка земли"
            },
            {
                id: "tag4c_4",
                text: "выравнивание участка"
            },
        ]
    },
    {
        id: "blog5",
        title: "вывоза мусора контейнером",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз мусора контейнером является одним из самых удобных и популярных
                способов утилизации отходов. Контейнеры для мусора бывают разных типов и
                размеров: от обычных мусорных баков до больших контейнеров объемом в
                несколько тонн. Давайте рассмотрим процесс вывоза мусора из контейнера и
                ключевые моменты, которые следует учесть.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Шаг 1: Выбор подходящего контейнера</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед заказом услуги по вывозу мусора контейнером необходимо выбрать
                подходящий контейнер. Размер контейнера определяется количеством и типом
                мусора, который будет собираться. Важно учитывать возможность перевозки
                контейнера до места утилизации, так как не все дороги и мосты позволяют
                проезжать транспорту с крупными грузами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Шаг 2: Оформление документов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед заказом услуги по вывозу мусорного контейнера следует подготовить
                документы, включая разрешение на установку контейнера на участке. Также
                нужно убедиться, что количество отклонений, исправленных на объекте, не
                превышает разрешенной нормы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Шаг 3: Установка контейнера</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                После оформления документов можно приступить к установке контейнера.
                Установка возможна только на том участке, где дорога имеет достаточную
                ширину для проезда грузового транспорта. Также установка контейнера
                возможна только на территории с учетом безопасности для людей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Шаг 4: Сбор мусора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сбор мусора осуществляется с помощью своевременной замены заполненных
                контейнеров на новые. Можно предоставить контейнер на место утилизации или
                указать транспортировку до места назначения. Станции обычно поставляются в
                контейнерах для определенных видов мусора: бумага, пластик, стекло.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Соблюдение правил техники безопасности</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сбор, транспортировка и утилизация мусора связаны с риском. Поэтому важно
                соблюдать меры предосторожности как при установке контейнера, так и при
                работе с мусором. При работе с крупными контейнерами следует регулярно
                проверять их состояние, так как поврежденный или старый контейнер может
                стать причиной возникновения опасных ситуаций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Использование контейнеров для мусора — один из самых практичных способов
                утилизации отходов. Этот метод позволяет эффективно утилизировать мусор и
                сэкономить время и ресурсы. Следуя простым шагам и соблюдая меры
                безопасности, можно убедиться, что процесс вывоза мусорного контейнера
                проходит гладко и без проблем.
              </span>
            </p>
          </>          
          ),
        tags: [
            {
                id: "tag5_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag5_2",
                text: "вывоз мусора"
            },
            {
                id: "tag5_3",
                text: "контейнеры для мусора"
            },
            {
                id: "tag5_4",
                text: "утилизация отходов"
            },
        ]
    },
    {
        id: "blog5a",
        title: "Как заказать контейнер для мусора на время строительства",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Заказ контейнера для мусора на время строительства — это простая и удобная
                услуга, которая позволяет поддерживать чистоту на строительной площадке. В
                этой статье мы расскажем, как правильно заказать контейнер и на что
                обратить внимание при его выборе.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Выбор контейнера</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед тем, как заказать услугу по вывозу мусора контейнером, необходимо
                выбрать подходящий контейнер. Размер контейнера определяется количеством и
                типом мусора, который будет собираться. Для строительных отходов лучше
                выбрать контейнеры большего объема, чтобы избежать частых замен.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Оформление документов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед заказом контейнера следует подготовить необходимые документы и
                получить разрешение на установку контейнера на участке. Это поможет
                избежать проблем с контролирующими органами и обеспечить бесперебойную
                работу на строительной площадке.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Установка контейнера</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Контейнеры для мусора устанавливаются только на тех участках, где дорога
                позволяет проезжать грузовому транспорту. Убедитесь, что место установки
                контейнера безопасно и удобно для использования рабочими.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Сбор и вывоз мусора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сбор мусора осуществляется путем замены заполненных контейнеров на новые.
                Контейнеры могут быть предоставлены на место утилизации, либо мусор может
                быть транспортирован до места назначения. Важно следить за своевременной
                заменой контейнеров, чтобы избежать накопления отходов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Соблюдение правил техники безопасности</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                При установке и использовании контейнеров для мусора необходимо соблюдать
                меры предосторожности. Регулярно проверяйте состояние контейнеров и
                следите за их исправностью, чтобы избежать аварийных ситуаций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Заказ контейнера для мусора на время строительства — это удобный и
                эффективный способ поддерживать порядок на площадке. Свяжитесь с нашей
                компанией для получения дополнительной информации и оформления заказа.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag5a_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag5_2",
                text: "контейнеры для мусора"
            },
            {
                id: "tag5_3",
                text: "вывоз мусора"
            },
            {
                id: "tag5_4",
                text: "строительство"
            },
        ]
    },
    {
        id: "blog5b",
        title: "Почему важно соблюдать график вывоза мусора",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Несвоевременный вывоз мусора чреват переполненными контейнерами,
                появлением грызунов, ухудшением санитарной обстановки и неприятными
                запахами. Жить в таких условиях некомфортно даже в относительном удалении
                от мусорных баков. В этой статье мы рассмотрим, как и куда жаловаться
                жильцам, если мусор не вывозят вовремя.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>График вывоза мусора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Летом при температуре от +5°C мусор необходимо вывозить ежедневно.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Зимой при температуре -5°C и ниже допускается вывоз мусора раз в 3 дня.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Редкие опоздания с вывозом, связанные с форс-мажорными обстоятельствами,
                допустимы в течение 2 дней зимой и суток летом. Федеральное
                законодательство также регулирует количество часов, в течение которых
                мусор не должен оставаться на месте: за месяц это время не должно
                превышать 72 часов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Куда жаловаться, если не вывозят мусор</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                При систематических нарушениях графика вывоза мусора жильцы могут
                обращаться в различные организации для решения проблемы:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Управляющая компания: опишите проблему и запросите контакты подрядчика.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Региональный оператор: в их компетенции решить проблему с вывозом
                  мусора.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Государственные органы: Роспотребнадзор, Министерство ЖКХ, жилищная
                  инспекция, региональные органы исполнительной власти.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Как составить жалобу</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для быстрого решения проблемы в жалобе необходимо указать:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Адрес дома.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Даты, с которых не вывозили мусор.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Расположение контейнеров.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Желательно приложить фото и видео доказательства, чтобы чиновники могли
                быстрее принять меры.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Ответственность за выброс мусора в чужой контейнер</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Каждый житель заключает договор на вывоз мусора со своей управляющей
                компанией. Контейнеры для мусора являются собственностью жильцов
                конкретного дома. Выброс мусора в чужие контейнеры посторонними людьми
                будет считаться нарушением имущественных прав.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Предотвращение нарушений</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Чтобы предотвратить нарушения, жильцы могут попросить управляющую компанию
                изолировать контейнеры, установив замок или камеры видеонаблюдения. Эти
                меры помогут сохранить порядок и предотвратить несанкционированный выброс
                мусора.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                В заключение, соблюдение графика вывоза мусора и правил техники
                безопасности важно для поддержания чистоты и санитарного состояния
                территории. Если вы столкнулись с проблемами, не стесняйтесь обращаться в
                соответствующие инстанции.
              </span>
            </p>
          </>
          ),
        tags: [
            {
                id: "tag5b_1",
                text: "аренда спецтехники"
            },
            {
                id: "tag5b_2",
                text: "график вывоза"
            },
            {
                id: "tag5b_3",
                text: "вывоз мусора"
            },
            {
                id: "tag5b_4",
                text: "санитарные нормы"
            },
        ]
    },
    {
        id: "blog6",
        title: "Вывоз земли (грунта)",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Во время строительства или благоустройства земельного участка его
                владельцы сталкиваются с проблемой вывоза и утилизации ненужного грунта.
                Этот грунт остается после выборки котлованов под строительство, прокладки
                различных инженерных коммуникаций, бурения скважин, копки колодцев и
                прочих работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Как заказать вывоз грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Чтобы заказать доставку грунта, вывоз мусора и услуги по благоустройству,
                вы можете позвонить нам, написать в чат WhatsApp или запросить обратный
                звонок.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности не плодородного грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Неплодородный грунт часто бывает замусоренным и требует правильной
                утилизации. Для этого его необходимо вывозить на специальные полигоны, где
                он утилизируется в соответствии с законодательством и экологическими
                нормами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Кто может вывезти грунт</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Важно заключать договор на вывоз и утилизацию грунта с подрядчиками,
                имеющими разрешительные документы на данный вид деятельности. Подрядчик
                должен иметь спецтехнику, предназначенную для этих целей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Процесс вывоза не плодородного грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Этапы работ</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Перемещение грунта по площадке до точки погрузки:&nbsp;
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Грунт перемещается до места, где возможно подъезд и стоянка самосвалов или
                контейнеров.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Погрузка грунта:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Погрузка грунта на самосвал при помощи спецтехники или вручную.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Транспортировка грунта:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Грунт перевозится самосвалами или контейнеровозами.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> Утилизация грунта:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Утилизация грунта в соответствии с экологическими нормами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Формирование цены на вывоз грунта</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Цены на вывоз грунта в Москве и МО зависят от нескольких факторов:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Объемы грунта: Чем больше объем, тем выгоднее цена.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Местоположение объекта: Расстояние до полигона влияет на стоимость.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Тип грунта: Загрязненный грунт требует дополнительных затрат.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Способ погрузки: Услуги с погрузкой будут стоить дороже.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Обращение с отходами строительства</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Законодательные нормы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз строительных отходов строго контролируется законодательными
                органами. Вступившая в силу в 2019 году мусорная реформа диктует правила
                сбора, транспортировки и утилизации отходов, особенно образовавшихся в
                ходе масштабных строительных или ремонтных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Категории грунтов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Грунты классифицируются по степени опасности:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Малоопасный грунт:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Содержит минимальное количество строительных отходов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Опасный грунт:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Загрязнен нефтепродуктами, тяжелыми металлами и прочими веществами.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Высокотоксичный грунт:&nbsp;</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сильно загрязнен токсичными элементами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Документальное оформление</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для вывоза строительного грунта в Москве и Подмосковье необходимо
                специальное разрешение. Все организации должны соблюдать требования и
                правила, подтверждая свои действия документально.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы утилизации грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Основные этапы</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Снятие верхнего слоя с растительностью.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Определение класса опасности грунта.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Сортировка и переработка грунта:&nbsp;
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Малоопасные грунты отправляются на переработку, а высокотоксичные
                подвергаются дезинфекции и захоронению.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Расчет стоимости вывоза грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Стоимость вывоза грунта зависит от нескольких факторов:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Объем и сложность работ.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Удаленность объекта от полигона.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Коэффициент разрыхления и набухания.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Дополнительные услуги: Привлечение спецтехники, контейнеров и рабочих
                бригад.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>&nbsp;Услуги компании "СпецУстройство"</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Наши преимущества</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания "СпецУстройство" специализируется на вывозе грунта и
                строительного мусора. Мы предоставляем полный комплекс работ, работаем с
                крупными компаниями и частными клиентами, обеспечивая быструю и
                качественную утилизацию.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Оборудование и техника</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для выполнения работ мы используем специализированную технику с высокой
                грузоподъемностью и управляемостью на любой поверхности. Наша техника
                соответствует всем требованиям и стандартам, что гарантирует высокое
                качество работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Документация и разрешения</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Мы обеспечиваем наличие всех необходимых разрешений и справок, что
                позволяет нам легально и безопасно выполнять работы по вывозу грунта.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Свяжитесь с нами по телефону или закажите обратный звонок, чтобы обсудить
                ваши задачи и получить консультацию по всем интересующим вопросам.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog7",
        title: "Вывоз грунта и строительного мусора",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                При разработке котлованов, прокладке труб и добыче полезных ископаемых
                требуется квалифицированная помощь в вывозе грунта. Компания
                «СпецУстройство» предлагает полный комплекс работ по вывозу грунта и
                строительного мусора в Москве и Московской области. Мы работаем как с
                крупными компаниями, так и с частными клиентами, которым нужно
                утилизировать более 100 м³. Гарантируем быстрое и качественное выполнение
                всех задач, предоставляем все необходимые документы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>&nbsp;Почему выбирают «СпецУстройство»</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания «СпецУстройство» специализируется на вывозе грунта и
                строительного мусора. Наши клиенты — крупные компании и частные лица,
                которым необходимо быстро и качественно вывести и утилизировать
                строительные отходы. Мы предоставляем все справки и выполняем работу на
                высоком уровне, гарантируя максимальное удовлетворение потребностей
                клиента.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Связаться с нами можно по телефону или заказав обратный звонок.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Устройство котлованов: Ключевой этап строительства</strong>
            </p>
            <p>
              <br />
              <br />
              <br />
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сооружение любого здания — долгий и трудоемкий процесс, начинающийся с
                нулевого цикла. Этот этап включает в себя комплекс строительных работ,
                подготавливающих место для возведения объекта. На нулевом этапе
                выполняются работы по разметке участка, устройству котлованов, засыпке и
                перемещению грунта, а также прокладке необходимых коммуникаций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Задачи нулевого цикла</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Разметка грунта: Первоначальная разметка участка для строительства.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Копание траншей: Копка траншей для закладки коммуникаций и фундамента.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Устройство котлована: Выкопка котлована под фундамент здания.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для выкапывания котлована применяется специализированная техника, такая
                как экскаваторы. В случае небольших строек используется пневмоколесный
                гидравлический экскаватор, для более масштабных проектов применяют мощную
                спецтехнику.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Транспортировка и утилизация грунта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                После выемки грунта его необходимо транспортировать для последующей
                утилизации. Этот процесс требует использования самосвалов. Утилизация
                подразумевает переработку, захоронение или повторное использование грунта.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Качественное устройство котлована</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                На следующем этапе начинается обустройство котлованов. От качества
                выполнения этих работ зависит прочность всей постройки, так как на основе
                этих работ выполняется обустройство фундамента. Фундамент может быть как
                сборным (из столбов дерева и металла), так и цельным. В последнюю очередь
                на этом этапе выполняется закладка кабелей, теплотрассы, водопровода,
                газопровода и канализации.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Стоимость рытья котлована</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Стоимость рытья котлована зависит от расходов на вывоз и утилизацию
                лишнего мусора. Под автопарком строительной техники подразумевается ряд
                машин, необходимых для перемещения и вывоза грунта со стройки. Подбор
                техники производится индивидуально, в зависимости от объема работ и
                обустройства котлована. Главным преимуществом является максимальная
                нагрузка и мощность спецтехники.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Факторы, влияющие на стоимость работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Объем вывоза груза: Чем больше объем, тем выше стоимость.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Срочность заказа: Срочные заказы требуют дополнительных расходов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Удаленность полигона: Расстояние до полигона для утилизации также влияет
                на цену.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Способ разработки и вывоз грунта: Способ выполнения работ определяет
                затраты на спецтехнику и рабочую силу.
              </span>
            </p>
            <p>
              <br />
              <br />
              <br />
            </p>
            <p>
              <strong>Преимущества компании «СпецУстройство»</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания «СпецУстройство» обладает всем необходимым оборудованием и
                техникой для выполнения работ по вывозу грунта и строительного мусора. Мы
                гарантируем высокое качество и оперативность выполнения задач, работаем по
                официальным договорам и предоставляем все необходимые документы. Свяжитесь
                с нами по телефону или закажите обратный звонок, чтобы обсудить ваши
                задачи и получить профессиональную консультацию.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog8",
        title: "Земляные работы",
        date: "05.10.2024",
        text: (<>
            <p>
              <strong>Как получить разрешение на снос строения</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Юридическое оформление</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Чтобы получить разрешение на снос строения, необходимо пройти процесс
                юридического оформления в офисе БТИ (Бюро технической инвентаризации) по
                месту регистрации имущества. Перед посещением этой организации владельцу
                нужно подготовить определенный пакет документов, который потребуется для
                подачи заявки на снос зданий.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Шаги получения разрешения на снос строения</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Подготовка документов</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>Проект организации проведения демонтажа</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Разработка графика выполнения демонтажа элементов
                конструкции.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Создание технологического регламента, в котором будет
                прописан порядок утилизации строительного мусора.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={2}>
              <li>
                <strong>Паспорт заявителя</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Копия паспорта лица, подающего заявку.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={3}>
              <li>
                <strong>Копия договора на вывоз отходов</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Заключение договора с компанией, занимающейся вывозом
                строительных отходов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={4}>
              <li>
                <strong>Смета проведения работ по демонтажу</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Подробный расчет стоимости всех этапов демонтажных
                работ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={5}>
              <li>
                <strong>Документы компании, планирующей проводить работы</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Документы, подтверждающие легальность и квалификацию
                компании, которой доверено разрушение сооружения.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={6}>
              <li>
                <strong>Заключение, подтверждающее проведение процедуры</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Официальное заключение, подтверждающее согласование
                всех этапов сноса и утилизации мусора.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Процесс подачи заявки</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Состав пакета документов, необходимых для подачи в БТИ, определяется
                индивидуально для каждого здания. Максимальный срок, в течение которого
                БТИ может рассматривать ходатайство, составляет 30 рабочих дней.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Важные моменты</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>-</span>
              <strong> Технический регламент:</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Он должен включать все аспекты безопасного проведения демонтажа и
                утилизации.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>-</span>
              <strong> Сопровождение проекта</strong>
              <span style={{ fontWeight: 400 }}>
                : Важно, чтобы все документы были в порядке и предоставлены в полном
                объеме, чтобы избежать задержек и отказов в получении разрешения.
              </span>
            </p>
            <p>
              <strong>- Соблюдение сроков</strong>
              <span style={{ fontWeight: 400 }}>
                : Подготовка и подача документов должны быть выполнены в установленные
                сроки, чтобы избежать штрафов и прочих санкций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Получение разрешения на снос строения — это важный этап, который требует
                тщательной подготовки и соблюдения всех юридических формальностей.
                Компания «СпецУстройство» предлагает полный комплекс услуг по подготовке и
                подаче необходимых документов, а также по выполнению всех видов земляных и
                демонтажных работ. Мы гарантируем качество и оперативность наших услуг,
                что позволит вам избежать ненужных задержек и осложнений. Свяжитесь с нами
                для получения консультации и детальной информации по всем вопросам.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog9",
        title: "Как демонтировать бетонный фундамент",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Причины для сноса зданий могут быть различными: аварийное состояние,
                необходимость очистки территории для строительства нового сооружения или
                другие цели. Общий порядок проведения работ по демонтажу всегда одинаков.
                В данной статье мы рассмотрим особенности демонтажа бетонного фундамента.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>&nbsp;Подготовительные работы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед началом демонтажа бетонного фундамента необходимо:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>Изучить документацию:</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Ознакомиться с проектной документацией, касающейся
                объекта проведения работ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Провести строительную экспертизу для оценки состояния
                фундамента.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong>Сбор сведений:</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Определить материал, из которого построен фундамент.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Выяснить, как глубоко фундамент уходит в землю.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Определить наличие и расположение инженерных
                коммуникаций.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Оценить общее состояние конструкции.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Подбор техники и оборудования</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                На основе собранных данных производится подбор специальной техники и типа
                оборудования для демонтажа. Это включает в себя:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Выбор экскаваторов и бульдозеров.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Подбор навесного оборудования, такого как бетоноломы и строительные
                буры.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности демонтажа фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Подготовка площадки</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Освобождение доступа:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Подготовить площадку для свободного доступа техники к
                объекту.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Убедиться, что местность остается относительно ровной,
                чтобы не создавать помех для работы оборудования.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Демонтаж</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Изменение характера воздействия:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- В зависимости от текущих потребностей, можно в течение
                5 минут сменить навесное оборудование с бетонолома на строительный бур.
                Это позволяет изменить характер воздействия на бетон.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Вывоз обломков:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Разрушенные части фундамента вывозятся с объекта с
                помощью бульдозеров, экскаваторов и грузовых автомобилей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж бетонного фундамента — это сложный и многоэтапный процесс,
                требующий тщательной подготовки и использования специальной техники.
                Компания «СпецУстройство» предлагает полный спектр услуг по демонтажу и
                утилизации строительных конструкций, обеспечивая высокое качество и
                оперативность выполнения работ. Свяжитесь с нами для получения
                консультации и детальной информации по всем вопросам.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Как демонтировать бетонный фундамент</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж бетонного фундамента — это сложная задача, которая требует
                тщательной подготовки и использования специализированного оборудования.
                Основные причины для сноса здания включают аварийное состояние строения
                или необходимость очистки территории для нового строительства. В этой
                статье мы рассмотрим, как правильно провести демонтаж бетонного
                фундамента.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Подготовка к демонтажу</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Изучение документации</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Прежде всего, необходимо внимательно изучить
                документацию, касающуюся объекта проведения работ. Важно ознакомиться со
                строительной экспертизой, чтобы понять:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Из какого материала построен фундамент.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Насколько глубоко фундамент уходит в
                землю.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Наличие инженерных коммуникаций.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Текущее состояние конструкции.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Подбор техники и оборудования</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- После сбора всех сведений производится подбор
                специальной техники и типа оборудования, необходимого для демонтажа
                фундамента.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности демонтажа фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Подготовка площадки</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Если принято решение о проведении демонтажа фундамента
                с помощью робота, необходимо подготовить площадку для свободного доступа
                техники к объекту. Неровности местности не станут помехой для
                использования робота.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Смена навесного оборудования</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Для изменения характера воздействия на бетон можно в
                течение 5 минут сменить навесное оборудование с бетонолома на строительный
                бур.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Вывоз обломков</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Обломки разрушенной конструкции вывозят с объекта с
                помощью бульдозера, экскаватора и грузовых автомобилей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж бетонного фундамента требует внимательного подхода и
                использования специализированной техники. Компания «СпецУстройство»
                предоставляет полный спектр услуг по демонтажу зданий и сооружений,
                включая бетонные фундаменты. Мы гарантируем качественное выполнение работ
                в кратчайшие сроки, обеспечивая безопасность и соблюдение всех нормативных
                требований. Свяжитесь с нами для получения консультации и заказа услуг по
                демонтажу.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog10",
        title: "Краны-манипуляторы для любых работ",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Краны-манипуляторы — это незаменимое оборудование для проведения
                погрузочно-разгрузочных работ и транспортировки грузов. Выбор техники
                должен основываться на грузоподъемности и габаритах манипулятора, а также
                объеме предполагаемых работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества крана-манипулятора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Почему среди разнообразия спецтехники подрядчики часто выбирают именно
                кран-манипулятор? Этот вид оборудования идеально подходит для решения
                широкого спектра задач:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Точность перемещения и размещения груза. Манипуляторы обеспечивают
                высокую точность при перемещении и размещении грузов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Погрузка и разгрузка. Краны-манипуляторы могут использоваться для
                погрузки и разгрузки товаров с последующей транспортировкой.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Мобильность. Они легко перемещаются между различными рабочими объектами.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Компактные габариты. В сравнении с другой спецтехникой, манипуляторы
                имеют компактные размеры, что позволяет их эксплуатацию в ограниченном
                пространстве, например, на стройплощадках.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Выбор модели для аренды</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                При выборе манипулятора важно учитывать условное разделение по типу
                стрелового оборудования, шасси и характеристикам грузоподъемности.
                Например, стреловое оборудование может быть выполнено в виде подвески или
                по типу стрелы. Подвески также различаются по конструкции — есть жесткие с
                гидравлическим управлением и гибкие с лебедочным тросом.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Типы стрелы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Тип Z. Такая стрела подходит для работы с нетяжелыми грузами, хорошо
                себя ведет на небольшой площадке, где мало места и много препятствий.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Тип L. Эта стрела предназначена для более точных и плавных операций,
                максимально эффективно распределяет нагрузки при перемещении тяжелых
                грузов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Выбор крана-манипулятора для аренды — это важный шаг, который требует
                учета многих факторов. Компания «СпецУстройство» предлагает широкий выбор
                моделей кранов-манипуляторов, подходящих для любых видов работ. Наши
                специалисты помогут вам подобрать оптимальную модель, исходя из ваших
                потребностей и условий работы. Свяжитесь с нами для консультации и аренды
                техники высокого качества
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog11",
        title: "Способы разработки котлована",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разработка котлована может выполняться как собственными силами, так и с
                использованием специализированной техники. Это важный этап строительства,
                требующий тщательной подготовки и соблюдения определенных технологических
                процессов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Что такое разработка котлована</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разработка котлована включает в себя несколько этапов:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Подготовка чертежа будущего объекта. На этом этапе создается проект
                  котлована, составляется смета и план будущих работ.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Подготовительные работы. До начала копки котлована необходимо провести
                  подготовительные мероприятия, такие как разметка территории и подготовка
                  оборудования.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Копка котлована. Непосредственный труд по выемке грунта является
                  завершающим этапом, которому предшествуют все подготовительные работы.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Способы разработки котлована и их особенности</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Существуют несколько способов разработки грунта для котлована:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> Механический способ.</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Описание: Грунт вырезается пластами определенных
                размеров при помощи специализированных машин, таких как экскаваторы.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Преимущества: Высокая точность и эффективность работы,
                возможность разработки котлованов любой сложности.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> Гидромеханический метод.</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Описание: Применяется при застройке вокруг пойм рек,
                низин и оврагов. Гидромеханическая установка активирует работу насосной
                станции, в результате чего грунт разрушается под действием мощных водных
                струй.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Преимущества: Эффективен для разработки грунтов с
                высоким уровнем грунтовых вод, минимизирует механическое воздействие на
                окружающую среду.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> Взрывной способ.</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;- Описание:&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Характеризуется мгновенным перемещением земляной массы в результате
                продуманного и рассчитанного взрыва. Для этого используются динамит,
                тротил или амониты.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Преимущества: Позволяет быстро и эффективно удалять
                большие объемы грунта, особенно в труднодоступных или скальных районах.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> Комбинированный способ.</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;- Описание:&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Совмещает механический способ с взрывным, что позволяет использовать
                преимущества обоих методов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Преимущества:&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Повышает эффективность работ, позволяет адаптироваться к различным
                условиям и типам грунта.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Выбор способа разработки котлована зависит от многих факторов, включая тип
                грунта, размеры котлована и условия строительства. Компания
                «СпецУстройство» предлагает услуги по разработке котлованов любым из
                перечисленных способов, обеспечивая высокое качество и соблюдение всех
                нормативов. Свяжитесь с нами для консультации и получения услуг
                профессионального уровня.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog12",
        title: "Применение колесного экскаватора",
        date: "05.10.2024",
        text: (<>
            <p>
              <strong>
                Колесные экскаваторы – это незаменимая спецтехника для расчистки
                территорий, перемещения стройматериалов, рытья траншей, каналов,
                котлованов, проведения ремонтных и строительных работ, реконструкции дорог
                и прокладки трубопроводных магистралей. Их универсальность и мобильность
                делают их идеальными для использования в различных строительных проектах.
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества колесного экскаватора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Современные колесные экскаваторы обладают множеством преимуществ, которые
                выгодно отличают их на фоне своих предшественников:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> Универсальность в использовании.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Колесные экскаваторы могут комплектоваться различным
                навесным оборудованием, таким как грейдеры, ножницы, гидравлические
                молоты, что значительно расширяет их функционал и области применения.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong> Мобильность и скорость передвижения.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Эти машины могут быстро перемещаться по строительной
                площадке, что позволяет оперативно решать поставленные задачи и
                значительно экономит время.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> Компактные габариты.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Благодаря небольшим размерам колесные экскаваторы
                подходят для использования в городских условиях и на площадках с
                ограниченной территорией. Это делает их идеальными для работ в узких
                местах и на сложных участках.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <strong> Отличная обзорность.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Высокая обзорность исключает вероятность ошибок
                оператора, повышая безопасность и точность выполнения работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <strong> Простота в эксплуатации.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Высокая эргономичность органов управления делает
                эксплуатацию колесного экскаватора простой и удобной, что позволяет
                снизить утомляемость оператора и увеличить производительность труда.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <strong> Минимальная нагрузка на грунт.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Колесные экскаваторы оказывают незначительную нагрузку
                на грунт, имеют высокую устойчивость, что повышает их проходимость в
                условиях бездорожья и делает их пригодными для работы на песчаных или
                болотистых почвах.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={7}>
              <li>
                <strong> Высокая производительность.</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Реализация современных конструктивных решений
                позволяет колесным экскаваторам достигать высокой производительности, что
                делает их эффективными для выполнения большого объема работ в короткие
                сроки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Применение колесного экскаватора в различных условиях</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Одно из ключевых преимуществ колесных экскаваторов – это возможность
                перемещаться по территории объекта или на соседнюю площадку, не повреждая
                асфальтовое покрытие. Техника одинаково эффективно может использоваться
                как в городских условиях, так и на песчаных или болотистых грунтах. Это
                делает их универсальными помощниками в строительных и ремонтных работах.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Колесные экскаваторы представляют собой важный элемент строительной
                техники, который сочетает в себе универсальность, мобильность и высокую
                производительность. Компания «СпецУстройство» предлагает аренду колесных
                экскаваторов, обеспечивая высокий уровень сервиса и профессионализма.
                Свяжитесь с нами для получения консультации и оформления заказа на аренду
                техники.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog13",
        title: "Технология проведения земляных работ",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Земляные работы — это комплекс процессов по обустройству участка,
                включающий геодезическую съемку, составление проекта ландшафта, рытье
                котлованов, вывоз почвы с помощью самосвалов и другие мероприятия. Перед
                началом любых земляных работ проводится тщательное геодезическое
                исследование рельефа участка, что позволяет подготовить его к дальнейшим
                строительным этапам.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности подготовительных работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сотрудники компании составляют вертикальный план участка, в котором
                указываются требования к проведению работ на территории. Важно учитывать
                условия для отводов грунтовой или дождевой воды, необходимый наклон
                поверхности, а также подготовку площадки для перемещения спецтехники и
                самосвалов. Чем тщательнее проработан план территории, тем проще и дешевле
                обходится организация вывоза почвы. Например, цена вывоза почвы из
                котлованов глубиной 10 м значительно выше стоимости вывоза со
                стройплощадки с комфортным подъездным путём.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вертикальные планировки участка помогают организовать нужные земляные
                работы и изменить рельеф территории, что позволяет подсыпать или удалить
                почву для достижения необходимых характеристик. В некоторых случаях
                производится замена почвы на наиболее подходящую для строительных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Этапы подготовительных работ</span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Создание вертикального плана участка:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Учитываются все требования для проведения земляных
                работ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Определяются условия для отвода воды и наклон
                поверхности.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Планируются подъездные пути для спецтехники и
                самосвалов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Подготовка к проведению земляных работ**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Согласование и подготовка сметно-строительных и
                разрешительных документов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Создание условий для минимального изменения
                существующего ландшафта.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Оптимизация затрат на подготовительные работы, включая
                рытьё котлованов и вывоз почвы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Технология проведения земляных работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Проведение земляных работ включает в себя множество факторов, таких как
                состав почвы, тип основания и уровень грунтовых вод. Например, столбчатое
                основание требует подготовки круглой ямы с вертикальными стенами, что
                обеспечивает устойчивость конструкции даже при сильном подъёме грунтовых
                вод.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Основные этапы проведения земляных работ:</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> **Геодезическая съемка**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Определение рельефа участка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Подготовка геодезического плана для дальнейших работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> **Подготовка грунта**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Разметка участка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Выкапывание траншей или котлованов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Учет крутизны откосов для строительства ленточного
                фундамента или котлована.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> **Строительство основания**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Подготовка дна углубления с помощью песка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Заливка слоя песка водой.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Укладка щебеночно-гравийных слоев.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Завершающая заливка бетона.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества профессионального проведения земляных работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Профессиональное проведение земляных работ специалистами компании
                «СпецУстройство» позволяет:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Обеспечить высокое качество выполнения всех этапов работ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Снизить затраты на проведение подготовительных мероприятий.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Минимизировать риски, связанные с неправильной подготовкой участка.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания «СпецУстройство» предлагает полный комплекс земляных работ,
                включая геодезическую съемку, составление проекта, рытьё котлованов и
                вывоз почвы. Свяжитесь с нашими специалистами для консультации и расчёта
                стоимости работ для вашего проекта.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog14",
        title: "Подготовка земельного участка к дренажу",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>Введение в дренажные системы</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Проект дренажной системы — это необходимая мера для владельцев участков со
                сложными условиями, большими территориями или избыточно увлажненной
                почвой. Дренаж позволяет поддерживать оптимальный уровень влажности и
                предотвращает повреждение построек. В данной статье рассмотрим, какие
                данные необходимы для проектирования дренажной системы и этапы её
                реализации.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Данные для проектирования дренажной системы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разработка дренажной системы для каждого участка — уникальная задача.
                Индивидуальность проекта определяется сочетанием природных и антропогенных
                факторов. Для начала проектирования дренажной системы необходимо собрать
                следующие сведения:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> **Конструкция постройки**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Тип и материалы, используемые в строительстве здания.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> **Генеральный план участка**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Детальная схема участка с расположением построек и
                других объектов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Топографическая съемка участка**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Точное измерение рельефа для определения направлений и
                уровня водоотвода.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> **План посадки**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Схема размещения растительности, учитывающая
                существующие и планируемые зеленые насаждения.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <span style={{ fontWeight: 400 }}> **Организация рельефа**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Информация о существующих уклонах и возвышенностях на
                участке.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Конструкция ливневой канализации**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Схема и описание существующей системы водоотведения.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={7}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Наличие инженерных сетей и коммуникаций**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Схемы подземных коммуникаций, таких как водопровод,
                газопровод и электрические сети.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Разработка проекта дренажной системы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                После сбора всех необходимых данных, специалисты нашей компании
                разрабатывают проект дренажной системы, который включает:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> **Схема дренажной трассы**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- План расположения дренажных труб и колодцев на
                участке.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Места расположения и размеры отдельных элементов системы**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Детализация размеров и мест установки дренажных
                элементов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Перечень и примерное количество строительных материалов**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- Список необходимых материалов для реализации проекта,
                включая трубы, фильтрующие материалы и колодцы.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Длительность и порядок проведения работ**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;- График выполнения работ, учитывающий все этапы
                установки дренажной системы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы подготовки участка к дренажу</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> Геодезическая съемка и анализ рельефа</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                На этом этапе проводится детальная геодезическая съемка участка для
                определения уклонов и мест возможного скопления воды. Это позволяет
                разработать эффективную дренажную систему.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong> Составление плана дренажной системы</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                На основе полученных данных разрабатывается план дренажной системы с
                учетом всех особенностей участка и построек на нём.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> Выбор материалов</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Определяется перечень необходимых материалов и их количество. Важно
                использовать качественные материалы для обеспечения долговечности системы.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <strong> Подготовка участка</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед началом основных работ проводится подготовка участка: выравнивание
                поверхности, удаление излишков почвы и подготовка траншей для укладки
                дренажных труб.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <strong> Монтаж дренажной системы</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                На этом этапе устанавливаются дренажные трубы и колодцы согласно проекту.
                Проводится проверка системы на герметичность и работоспособность.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <strong> Завершающие работы</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                После установки системы проводится засыпка траншей и восстановление
                ландшафта. Проверяется корректность функционирования дренажной системы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества профессионального подхода</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Компания «СпецУстройство» предлагает полный комплекс услуг по разработке и
                установке дренажных систем. Обращаясь к нам, вы получаете:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Качественный проект, учитывающий все особенности вашего участка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Использование современных и надежных материалов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Профессиональный монтаж дренажной системы с гарантией.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Консультации и поддержку на всех этапах работы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Свяжитесь с нашими специалистами для получения подробной консультации и
                расчета стоимости услуг.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog15",
        title: "Какой песок выбрать для строительства",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Песок – это универсальный нерудный материал, широко используемый в
                строительных работах. Он входит в состав различных строительных смесей и
                насыпей. Для успешного строительства важно правильно выбрать тип песка,
                учитывая его свойства и предназначение.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Виды песка, их достоинства и недостатки</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> Природный речной (морской) песок</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Описание**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Морской и речной песок добывается в водоемах и проходит дополнительное
                промывание. Этот вид песка отличается высокой прочностью и минимальным
                содержанием примесей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Преимущества**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Высокая прочность</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Минимальное количество примесей</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Подходит для заливки фундамента, строительства стен и колонн
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Недостатки**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Высокая стоимость</span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong> Натуральный карьерный (овражный) песок</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Описание**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Карьерный песок добывается в карьерах и имеет более доступную стоимость по
                сравнению с морским и речным песком. Он широко используется в
                строительстве.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Преимущества**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Доступная стоимость</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Хорошие показатели прочности</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Недостатки**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Большее количество примесей по сравнению с речным песком
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> Искусственный кварцевый песок</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Описание**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Кварцевый песок производится путем дробления горных пород. Этот вид песка
                отличается высокой прочностью и отсутствием примесей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Преимущества**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Высокая прочность</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Отсутствие примесей</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Разнообразие расцветок</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Недостатки**:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Высокая стоимость</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Ограниченное применение в строительстве из-за цены
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Рекомендации по выбору песка</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Для фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для заливки фундамента рекомендуется использовать речной или морской
                песок. Он обладает высокой прочностью и минимальным количеством примесей,
                что обеспечивает долговечность конструкции.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Для дренажа и бетонирования дорожек</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для дренажных работ и бетонирования дорожек подходит карьерный песок. Он
                достаточно прочен и более доступен по цене.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Для декоративных работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Кварцевый песок благодаря своему разнообразию расцветок и отсутствию
                примесей идеально подходит для декоративных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Выбор песка для строительства зависит от конкретных задач и бюджета
                проекта. Природный речной и морской песок подходит для ответственных
                конструкций, карьерный песок – для большинства строительных задач, а
                кварцевый песок – для декоративных и специализированных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обратившись в нашу компанию, вы можете заказать разработку котлованов и
                другие строительные услуги в Москве. Наши специалисты помогут выбрать
                подходящий тип песка для вашего проекта и обеспечат качественное
                выполнение работ.
              </span>
            </p>
            <p>
              <br />
              <br />
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
              <strong>Что такое земляные работы и этапы их проведения</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Строительство любого нового здания, от частного жилого дома до
                промышленного предприятия, начинается с выполнения земляных работ. Чем
                более грамотно и продуманно будут осуществлены земляные работы, тем
                надежнее и долговечнее получится построенное здание.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Что такое земляные работы и их этапы</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Земляные работы включают:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Расчистку территории</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Планировку земли</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Проектирование фундамента</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Разработку котлованов</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Копку траншей, сливных ям, бассейнов, колодцев
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Вывоз грунта</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Трамбовку земли</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Основные этапы проведения земляных работ:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}> **Расчистка территории**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Удаление растительности, мусора и других
                препятствий.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Подготовка участка для дальнейших работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}> **Планировка земли**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Выравнивание поверхности участка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Создание необходимого наклона для водоотведения.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}> **Проектирование фундамента**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Разработка плана фундамента на основе
                геодезических исследований и инженерных расчетов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Подготовка документации и согласование с
                контролирующими органами.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}> **Разработка котлованов**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Выкапывание котлованов под фундамент, подземные
                коммуникации и другие конструкции.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Использование специализированной техники, такой
                как экскаваторы и самосвалы.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Копка траншей, сливных ям, бассейнов, колодцев**:
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Выкапывание траншей для прокладки коммуникаций.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Создание сливных ям, бассейнов и колодцев для
                различных нужд.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <span style={{ fontWeight: 400 }}> **Вывоз грунта**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Удаление излишков грунта с участка.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Транспортировка грунта на специальные полигоны
                для утилизации.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={7}>
              <li>
                <span style={{ fontWeight: 400 }}> **Трамбовка земли**:</span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Уплотнение грунта для повышения его несущей
                способности.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;- Подготовка основания для строительства.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Важные моменты при проведении земляных работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Погодные условия**: Земляные работы следует проводить при
                благоприятных погодных условиях, чтобы избежать дополнительных циклов
                работ по очистке земли. Начинать земляные работы в зимний период
                рекомендуется только в случае необходимости осушения болотистой местности.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Выбор компании**: При выборе компании для проведения земляных работ
                следует обращать внимание на наличие различной техники и квалифицированных
                специалистов. Это обеспечит качественное и быстрое выполнение работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Земляные работы – это важный этап в строительстве, от которого зависит
                долговечность и надежность будущего здания. Обратившись в нашу компанию,
                вы можете заказать разработку котлована и другие земляные работы по
                доступной цене. Наши специалисты гарантируют высокое качество выполнения
                работ благодаря опыту и наличию современной техники.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog16",
        title: "Демонтаж металлоконструкций в Москве недорого",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж металлоконструкций – это сложный и ответственный процесс,
                требующий профессионального подхода и использования специализированной
                техники. Наша компания предоставляет услуги по демонтажу
                металлоконструкций в Москве по доступным ценам. Мы гарантируем
                качественное выполнение работ в кратчайшие сроки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Деформация фундамента и её виды</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Важность правильного фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                В основании любого строения лежит фундамент. От его качества зависит
                долговечность и устойчивость всего здания. Фундамент, построенный с
                соблюдением всех строительных норм и правил, может служить дольше самого
                строения, что особенно заметно на примере исторических памятников, от
                которых часто остаётся только фундамент в отличном состоянии.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Что необходимо учитывать при строительстве фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Фундамент должен выдерживать нагрузку от строения и равномерно
                распределять её по поверхности грунта. Деформация фундамента
                свидетельствует о просчётах при его устройстве. По статистике, около 20%
                случаев деформации связаны с ошибками проектирования, а остальные –
                результат просчётов исполнителей работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Виды деформации фундаментов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все виды деформации фундамента указывают на серьёзные ошибки, допущенные
                при его строительстве. Для выявления точных причин аварийной ситуации
                проводится комплекс мероприятий согласно нормативным документам.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Основные виды деформации:</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Прогиб** – результат неравномерного распределения нагрузки.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Выгиб** – возникает при превышении допустимых нагрузок.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Осадка** – вызвана неправильной оценкой свойств грунта.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Наклон** – свидетельствует о неравномерной осадке грунта под
                  фундаментом.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Скручивание** – происходит из-за неправильной укладки арматуры.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Обращайтесь в нашу компанию</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если вам требуется профессиональная разработка котлованов в Москве,
                обращайтесь к нам. Мы обеспечим качественное выполнение всех земляных
                работ и гарантируем удовлетворение ваших требований.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж металлоконструкций и проведение земляных работ – это сложные
                процессы, требующие профессионального подхода и использования
                специализированной техники. Наша компания предлагает доступные и
                качественные услуги в Москве, гарантируя высокий уровень выполнения работ.
                Свяжитесь с нами для получения дополнительной информации и консультации.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog17",
        title: "Демонтаж котельных и котельного оборудования в Москве",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж котельной – это сложный процесс, который невозможно выполнить без
                квалифицированных специалистов. Для выполнения таких задач, как демонтаж
                труб котельной, необходима помощь промышленных альпинистов со специальным
                допуском, так как этот вид работ является очень опасным. Важную роль в
                обеспечении безопасности и надежности работ играет оборудование, которое
                необходимо выбирать с учетом масштабов объекта и наличия подъездных путей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Почему важен профессиональный подход</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Работы по демонтажу котельной требуют исключительно профессионального
                подхода. Необходимо, чтобы все работы выполняли высококвалифицированные
                специалисты с опытом, позволяющим эффективно решать любые поставленные
                задачи. Наша компания уже многие годы предоставляет услуги по демонтажу
                котельных зданий, гарантируя оптимальные сроки выполнения ремонтных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Составляющие стоимости демонтажа котельной</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтажные работы на котельной включают комплекс мероприятий, таких как
                резка, погрузочные работы, полная или частичная утилизация в металлолом
                стальных деталей и конструкций. На цену демонтажных работ влияют следующие
                факторы:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Масштабы постройки</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Характер дальнейших работ (обычный снос или поэтапные демонтажные
                работы)
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наша компания предлагает конкурентоспособные цены на демонтаж котельной,
                что можно легко проверить, ознакомившись с нашими тарифами. После
                демонтажа котельной техники, оборудование не отправляется на свалку: котлы
                или трубы котельных можно сдать в металлолом. Мы также готовы предложить
                отличные условия по подготовке лома для перевозки, взвешиванию и расчету
                за металл сразу после завершения демонтажных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Когда требуется демонтаж котельной</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вопрос демонтажа котельной и избавления от металла часто становится первым
                шагом обновления производственного объекта. Снос хозяйственных зданий и
                вывоз металла производится сотрудниками компании по стоимости за тонну.
                Для выполнения этих задач требуется привлечение специального оборудования.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности демонтажа котельной</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Процедура демонтажа котельной проходит в несколько этапов. Для выполнения
                любой технической операции требуется участие квалифицированных
                специалистов и сложной техники. Прежде чем приступить к демонтажу
                котельной и сдаче здания на металлолом, необходимо получить официальное
                разрешение от коммунальных служб и отключить все системы подачи воды и
                электроэнергии. Стоимость демонтажных работ зависит от сложности самого
                строения и общего объема металла. Вывоз производится строительным
                оборудованием из-за больших размеров и массы некоторых деталей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества работы с нами</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наша компания предлагает услуги по разработке котлованов в Москве и
                Московской области. Мы выполняем демонтаж котельных по стоимости за 1
                тонну металлолома и проводим вывоз металлолома, чтобы вы могли начать
                строительные работы в ближайший срок. Мы гарантируем соблюдение сроков и
                высокое качество выполнения работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж котельных и котельного оборудования – это сложный процесс,
                требующий профессионального подхода и использования специализированной
                техники. Наша компания предлагает доступные и качественные услуги в
                Москве, гарантируя высокий уровень выполнения работ. Свяжитесь с нами для
                получения дополнительной информации и консультации.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog18",
        title: "Демонтаж металлоконструкций в Москве недорого",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж металлоконструкций представляет собой процесс разборки, вывоза и
                утилизации разобранных деталей. Эта услуга необходима при сносе зданий,
                обновлении производственной техники и других ситуациях. Такие работы
                должны выполнять только опытные и высококвалифицированные специалисты,
                применяющие различные технологии.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Когда нужен демонтаж металлоконструкции?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Разбор металлоконструкций может понадобиться в следующих случаях:
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Владельцы хотят изменить или расширить область деятельности.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - На предприятии планируется замена планировки помещений.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Компания планирует сменить технику из-за запуска новых производственных
                линий.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Необходимо провести демонтаж аварийных помещений.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - При реконструкции объекта, где проводился частичный демонтаж некоторых
                деталей металлоконструкции.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Для оптимизации складской логистики необходимо осуществить вывоз к месту
                производства и т.д.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Кроме того, если объект из металлоконструкции имеет временный характер и
                был заранее построен для последующего демонтажа, его стоит разобрать.
                Ситуаций, требующих демонтаж металлических конструкций, может быть много,
                и каждая требует индивидуального подхода.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Почему лучше доверить работу профессионалам?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Стоимость демонтажа металлоконструкций зависит от типа объекта, но
                разбирать даже маленькую металлоконструкцию нужно по строгим правилам.
                Профильные каркасы разрезают на несколько частей, которые затем грузятся
                на технику и вывозятся для переработки. Осуществлять такие работы можно
                только с помощью специальной техники и специалистов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз демонтированной металлоконструкции также представляет определенные
                сложности из-за большой массы деталей. Для погрузочных работ применяется
                спецтехника, для работы с которой также нужны высококвалифицированные
                сотрудники.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все эти вопросы просто решаются при заказе демонтажа разборки
                металлических конструкций с дальнейшей сдачей их на металлолом. В этом
                случае вы полностью избавляетесь от проблем и можете заработать на
                реализации металла.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Почему наша компания?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества нашей компании:</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Гибкость в объемах:**&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Мы беремся за любой объем или объект, будь то квартиры, офисы или
                промышленные строения. В нашем штате около 90 квалифицированных
                мастеров-демонтажников.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Современная техника:**</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;Используем различную технику торговых марок Bosch, Makita, DeWalt,
                Ryobi. Применяем только сертифицированное оборудование известных брендов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Опыт:**</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;У нас многолетний опыт работы в этой области. Мы выполнили более
                1000 заказов. Наши специалисты – настоящие профессионалы.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Уборка:**</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                &nbsp;Предлагаем вывоз мусора. Убираем за собой все лестничные площадки и
                лифт.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Соблюдение сроков:**&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Точно соблюдаем сроки, так как знаем, насколько это важно для клиентов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **Отсутствие предоплаты:**</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;Работаем без предоплаты.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж металлоконструкций – это сложный процесс, требующий
                профессионального подхода и использования специализированной техники. Наша
                компания предлагает доступные и качественные услуги в Москве, гарантируя
                высокий уровень выполнения работ. Обращайтесь к нам, чтобы заказать также
                земляные работы в Москве.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog19",
        title: "Демонтаж плит перекрытий в Москве: цена за м²",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж плит перекрытий является важным этапом при капитальных и
                некапитальных строительных работах. Цена за м² зависит от множества
                факторов, таких как состояние здания, используемые строительные материалы,
                технологии и сложность работ.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Что включает в себя демонтаж плит перекрытий?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж железобетонных плит перекрытий — это сложный процесс, требующий
                профессионального подхода. Основные этапы работ:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Осмотр конструкции: Специалисты компании проводят бесплатный осмотр
                  здания для составления сметы.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Подбор метода демонтажа: В зависимости от особенностей здания выбирается
                  механический, ручной или комбинированный метод.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Подбор спецтехники и специалистов: Для выполнения работ привлекается
                  необходимая спецтехника и квалифицированные специалисты.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Подготовка территории: Возводятся защитные ограждения, оборудуются
                  временные подъездные пути, на территорию завозится техника.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Отключение инженерных коммуникаций: Перед началом работ отключаются все
                  инженерные сети.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Освобождение и укрепление уровня: Укрепляется и защищается уровень,
                  находящийся под демонтируемым перекрытием.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Демонтаж плит: В зависимости от материала плит выбирается подходящая
                  технология демонтажа (например, алмазная резка для железобетона).
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                После демонтажа плиты загружаются на технику и отправляются на дальнейшую
                утилизацию. При необходимости мы можем сохранить плиты для повторного
                использования, минимизируя повреждения.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Технологии демонтажа плит перекрытий</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж железобетонных плит перекрытий осуществляется строго по
                строительным нормативам и правилам безопасности. Наиболее распространенные
                методы:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Алмазная резка: Позволяет обрабатывать бетон без пыли и сильного шума, в
                отличие от ударного метода.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Механический метод: Использование специализированной техники для
                разборки плит.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Комбинированный метод: Сочетание ручного и механического способов для
                достижения наилучшего результата.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества сотрудничества с нами</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Современная спецтехника: У нас есть вся необходимая техника для
                выполнения демонтажа как двухэтажных, так и многоэтажных строений.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Соблюдение правил безопасности: Мы строго следуем правилам безопасности
                и экологическим нормам.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Вывоз отходов: Все строительные отходы вывозятся на полигоны.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Соблюдение сроков: Мы всегда укладываемся в сроки сдачи проекта и готовы
                вносить объективные коррективы.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Доступные цены: Учитываем лояльную ценовую политику и предлагаем аренду
                строительной техники недорого.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Стоимость услуги</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Цена услуги: от 2000 руб/плита</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обращайтесь в нашу компанию для заказа демонтажа плит перекрытий в Москве
                и аренды строительной техники. Мы гарантируем качественное выполнение
                работ в оптимальные сроки.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog20",
        title: "Демонтаж фундамента в Москве по доступной цене",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж фундамента представляет собой важнейшую операцию, которая
                предшествует реставрации (замене либо ремонту) основания жилого объекта.
                После демонтажа проводится снос старых построек, которые мешают новому
                владельцу участка. Самым трудоемким этапом сноса любых построек считается
                демонтаж фундамента, при этом и цена за м³ высокая. Данная часть
                конструкции изготавливается из надежного армированного материала,
                разрушить который без использования современной технологии довольно
                трудно.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Методы демонтажа основания</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед тем как разрушать старое основание, специалисты нашей компании
                отправляются на объект и осуществляют оценку его состояния. Во время
                осмотра принимаются во внимание материалы изготовления основания –
                кирпичные либо бетонные, и его вид: свайный, столбчатый, ленточный и т.д.
                От данных моментов и зависит цена на демонтаж.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                К основным методам демонтажа фундамента, цена которого доступна для
                каждого, относятся:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **С помощью гидравлического пресс-цилиндра.** При демонтаже основания на
                  небольших участках, где предъявляются повышенные требования к вибрациям
                  и шуму, этот способ является самым оптимальным.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **С помощью алмазных инструментов.** Чтобы выполнить демонтаж
                  высокопрочного фундамента из железобетона, применяется алмазный
                  инструмент. Этот способ обладает массой преимуществ: пониженным уровнем
                  шума и вибрациями, однако цена на него окажется немного выше
                  предыдущего.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **С помощью гидравлического инструмента.** Данный вид демонтажа
                  применяется постоянно, так как его стоимость самая доступная. Старый
                  фундамент разрушается под влиянием гидромолота для экскаваторов либо
                  пневматического ручного молотка. Это отличный выбор для демонтажа
                  монолитных фундаментов и ленточных. При разборе конструкции кирпичных
                  или бетонных оснований она дробится на мелкие части.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наши сотрудники выполнят работы в короткий срок, как с несложными
                демонтажными работами на ленточном фундаменте, так и с разрушением
                монолитных оснований. При необходимости в работе применяется мощная
                гидравлическая техника, что дает возможность устранять фундамент
                промышленной большой постройки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Расценки на демонтаж и снос фундамента</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Цена услуги - от 650 руб/м³**</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Стоимость демонтажа фундамента зависит от множества факторов. Разрушить
                основу маленькой постройки гораздо легче, чем проводить демонтаж
                фундамента высотных или промышленных комплексов. Если рядом со зданием
                имеется жилой дом, применять ударный способ разрушения не представляется
                возможным, и специалисты компании используют безопасную технологию,
                например, алмазную резку. Среди важных факторов оказываются:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- расположение объекта;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- вид фундамента;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- глубина его залегания;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - необходимость вывоза строительного мусора;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - расстояние до ближайшего полигона для утилизации ТБО.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Еще одним важным фактором является выбор метода выполнения работ. Разбор
                фундамента вручную осуществляется лишь для небольшой по размерам
                конструкции. Более заглубленное и прочное основание нуждается в применении
                спецоборудования (навесной техники, гидравлического молота), что всегда
                является дополнительной статьей расхода.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Есть разные способы, которые позволяют проводить демонтаж объекта, в
                зависимости от которых стоимость услуги демонтажа фундамента может
                значительно отличаться.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества нашей компании</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наша компания осуществляет демонтаж фундамента из ФБС, бутовых камней,
                кирпича, ЖБИ любых видов. Для этого мы располагаем всем необходимым:
                техоснащением и специалистами, которые имеют огромный опыт в выполнении
                таких проектов. Основные аргументы в пользу выбора нашей компании для
                сотрудничества:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Демократичные расценки на услуги:** Благодаря наличию собственных
                технических и кадровых ресурсов, мы можем выполнять все работы без
                привлечения сторонних компаний.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Скорость выполнения работ:** Мы всегда находим возможность осуществить
                заказ в удобное для клиентов время.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Отличная репутация:** Более 7 лет успешной работы на строительном
                рынке.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Предоставление всех видов услуг:** Связанных со строительными,
                демонтажными или земляными работами, обустройством инженерной
                коммуникации.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Наличие всех необходимых сертификатов и лицензий:** Постоянное
                прохождение сотрудниками учебных переподготовок.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                К тому же демонтаж блочных фундаментов в основном осуществляется вручную,
                так как одной из важных особенностей применяемых для его возведения
                материалов (ФБС) является возможность их повторного использования по
                прямому назначению. Однако такая работа требует затрат длительного времени
                для удаления швов с помощью отбойного молотка, отгиба монтажной петли,
                приведения их в вертикальное состояние.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обращайтесь в нашу компанию, чтобы заказать демонтаж фундамента в Москве
                по доступной цене!
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog21",
        title: "Разбор старых зданий",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разбор старых зданий является одной из главных специализаций нашей
                компании. Мы располагаем всей необходимой техникой для проведения подобных
                работ, а в штате компании числятся дипломированные сотрудники с огромным
                опытом, что позволяет провести снос дома в сжатый срок с отличным
                качеством.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Почему стоит выбрать нашу компанию?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если вам необходимо разобрать дом и вы не хотите постоянно искать
                строительные компании, обещающие быструю разборку, то наша компания – ваш
                надежный выбор. Мы гарантируем ответственный подход к работе и высокое
                качество выполнения. Наши специалисты являются профессионалами своего дела
                и готовы справиться с любыми задачами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Основные виды домов для сноса:</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Деревянный дом</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж деревянного дома наши специалисты проводят вручную и с помощью
                специальной техники. Выбор зависит от объемов строения и территории. Если
                необходимо осуществить снос небольшого деревянного дома, то рекомендуется
                обратиться к рабочим. Если же это огромная конструкция с массивным
                основанием, то рекомендуется использовать механизированный способ, где
                кроме техники, используются специальные инструменты.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Кирпичный дом</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж кирпичного дома с бетонным фундаментом является очень трудоемким
                процессом. Здесь применяется различная техника, например, мини-эвакуатор и
                гусеничный трактор. Благодаря такой технике можно осуществить снос как
                старой стены, так и всего дома. Однако этот способ отличается высокой
                ценой.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества нашей компании:</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Опытные специалисты:** Наши работники имеют за плечами огромный опыт
                  демонтажных работ и применяют в работе специализированную технику и
                  инструменты.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Широкий спектр услуг:** Мы надежно выполняем работы с любым видом
                  материалов – кирпич, панели, монолит, дерево и т.д.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Длительное сотрудничество:** Мы заинтересованы в длительной
                  плодотворной работе с каждым нашим клиентом: предлагаем приемлемую
                  стоимость услуг, учитываем сроки, предлагаем вывоз мусора контейнерами и
                  многое другое.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Подписание соглашения:** При работе с нашими клиентами обязательно
                  подписываем соглашение, где подробно описываются сроки и другие детали.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Собственный парк техники:** У нас есть собственная техника.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Соблюдение норм безопасности:** Строго соблюдаем правила и нормы
                  техбезопасности.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Соблюдение сроков:** Укладываемся в указанный срок.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Сколько длятся работы?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Цена услуги - от 500 руб/м³**</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                От возникновения строительной площадки до благоустройства территории после
                сноса проходит примерно несколько месяцев. В эти работы входят:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- Ограждение территории.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Монтаж временного хранилища для отходов.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Вырубка деревьев высотой до 5 метров.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Ручная разборка зданий по деталям.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Механизированный снос железобетонных «скелетов» и многое другое.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обращайтесь в нашу компанию, чтобы заказать разбор старых зданий в Москве
                по доступной цене!
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog22",
        title: "Разборка зданий",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                В последнее время проблема разборки и демонтажа зданий и сооружений
                становится особенно актуальной, особенно в крупных городах. Причины
                необходимости проведения подобных работ могут быть различными:
                освобождение места под новое строительство, износ или аварийное состояние
                строения и другие. В любом случае, данные работы невозможно провести
                самостоятельно, не прибегая к помощи профессионалов. Только специалисты
                смогут обеспечить ликвидацию с учетом всех необходимых факторов, что
                позволит избежать повреждения находящихся поблизости объектов
                инфраструктуры и обеспечит безопасность людей и техники.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Услуги компании</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наша компания предлагает услуги по разборке и сносу зданий и сооружений
                любой категории сложности. Разбор конструкций производится как с
                использованием спецтехники, так и ручным трудом.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы демонтажа</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж проходит в несколько этапов:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Планирование**. Выполняется оценка постройки: год возведения объекта,
                  виды используемых материалов и технология строительства. Изучается
                  рельеф местности, близлежащие объекты инфраструктуры, имеющиеся условия
                  для подъезда спецтехники.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;&nbsp;</span>
            </p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Составление проекта**. Определяется комплекс необходимых работ,
                  который оформляется в соответствующий проект.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Подготовка**. Выносится весь мусор, удаляются остатки остекления,
                  электропроводки, деревянных и металлических конструкций. Организуются
                  подъездные пути и монтируется временное ограждение.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Собственно, сам демонтаж**. С учетом конструктивных особенностей
                  постройки, плотности застройки, инфраструктуры местности применяется
                  определенный способ: ручной, механизированный либо с применением
                  взрывчатых веществ. Обычно выбирается механизированный способ, при
                  котором демонтаж выполняется постепенно, начиная с верхних этажей и
                  заканчивая подвалом и фундаментом. Для верхней и нижней части строения
                  подбирается разная техника. Ликвидация верхних этажей зданий
                  осуществляется с помощью гидромолота и гидроножниц, а нижние разрушают
                  мощные экскаваторы.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Заключительная стадия**. Весь строительный мусор сортируется и
                  вывозится самосвалами на специализированный полигон для утилизации.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                В нашей компании демонтаж всех строительных конструкций выполняется под
                наблюдением мастера или прораба.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Цена</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Окончательно определить цену на разбор здания возможно лишь после его
                осмотра. На стоимость оказывают влияние несколько факторов, основные среди
                них:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Площадь и этажность конструкции.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Близлежащая инфраструктура.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Тип фундамента.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Вид перекрытий.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Помимо перечисленного, в стоимость включаются дополнительные услуги,
                например, вывоз строительного мусора или его доставка на вторичную
                переработку.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наша компания выполняет разборку зданий и сооружений любой площади и
                архитектуры. Мы полностью проводим комплекс работ "под ключ", который
                начинается с планирования сноса и заканчивается утилизацией строительного
                мусора. Наличие материально-технической базы позволяет качественно
                производить разборку зданий любой сложности в короткие сроки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обращайтесь к нам, чтобы заказать профессиональную разборку зданий в
                Москве и Московской области!
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog23",
        title: "Демонтаж ангаров в Москве: снос и разборка недорого",
        date: "05.10.2024",
        text: (<>
            <p>
              <strong>Описание услуги</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сооружения на основе металлоконструкций – стойкие, долговечные и несложные
                в монтаже. Неудивительно, что востребованность таких строений
                увеличивается. В случае истечения времени эксплуатации ангаров или же
                необходимости перенести объект на новое место, требуется демонтаж.
                Демонтаж ангаров невозможно провести без помощи профессионалов,
                использования инструментов и оборудования для резки металла, а также
                специализированной техники: манипуляторов, автокранов и грузовиков. Также
                снос ангаров – сложная работа, требующая соблюдения требований
                безопасности. В зависимости от задач демонтажа, подбирается оборудование и
                инструменты.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы демонтажа</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разбор металлоконструкций проводится без обеспечения целостности строения.
                Однако при необходимости процессы проводим так, чтобы не разрушить
                отдельные части конструкции для дальнейшего применения. Разбираются части
                каркаса и прочие надземные несущие конструкции, обшивка и крыша, части
                фундамента, коммуникации и электрической сети.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Основные этапы демонтажа:</strong>
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Планирование**. Исследование состояния строений и составление плана
                  мероприятий.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Оценка состояния**. Специалисты оценивают место строения, осматривают
                  объект и оценивают его состояние. Определяют, какая потребуется
                  спецтехника и установки, и каких экспертов надо привлечь.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Разборка конструкций**. Разборка профнастила, панелей и ограждений,
                  затем частей каркаса и несущих конструкций.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}> **Очистка участка от мусора**.</span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Что предлагаем мы?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж ангара из металлоконструкций – одна из услуг нашей компании. Мы
                работаем на территории Москвы и области. Сегодня мы заняли лидирующие
                места в данном сегменте рынка и продолжаем активно развиваться. Мы
                закрепили за собой репутацию надежного предприятия, которое оказывает
                комплекс демонтажных работ. Благодаря штату специалистов мы справляемся с
                задачами любого уровня сложности. Наличие инновационной техники позволяет
                нам проводить работы в короткие сроки. У нас выгодные расценки на перечень
                оказываемых услуг, а для крупных заказчиков действует система скидок. Мы
                гарантируем качество работ, в чем уже успели убедиться наши заказчики с
                разных частей страны.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Стоимость демонтажа ангаров</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Цены на демонтаж ангаров зависят от следующих факторов:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Сложность и объемы мероприятий.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Площадь сносимого строения.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Тип металлоконструкций.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Время, которое уйдет на работу.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Необходимость в привлечении тяжелой техники и бригады демонтажников.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Высотность сооружения.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Доступность подъезда к конструкции и география.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Необходимость в утилизации мусора, перевозки и т.д.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества нашей компании:</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Современная техника: грузовики, краны и техника с манипуляторами,
                агрегаты для резки металла.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Штат специалистов: резчики, стропальщики, промышленные альпинисты,
                специалисты-оценщики.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Работа на территории Москвы и области.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Выгодные расценки и система скидок для крупных заказчиков.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Качество работ, подтвержденное положительными отзывами клиентов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                **Стоимость услуги - от 250 руб/м3**
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для получения точной стоимости услуг свяжитесь с нашими менеджерами. Они
                расскажут о деталях сотрудничества и ответят на интересующие вопросы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Контактная информация</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Обращайтесь к нам, чтобы заказать демонтаж ангаров в Москве и Московской
                области.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog24",
        title: "Демонтаж конструкций в Москве",
        date: "05.10.2024",
        text: (<>
            <p>
              <strong>Описание услуги</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж строительных конструкций – категория сложных работ, проведение
                которой предполагает особый порядок, а также наличие спецтехники,
                профильных знаний и опыта. Демонтаж объектов ведется на основе проектов,
                генерального плана, ППР, ПОС, разработанных экспертами. Это особая
                категория, поскольку некоторые объекты имеют историческое и культурное
                значение, здесь следует учесть плотную городскую застройку, массу
                наземных, подземных и воздушных коммуникаций. Также следует учитывать все
                детали каждого конкретного строения, а также территории, на которой он
                располагается, фактор близости автотрасс к нему и прочих строительных
                объектов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все эти тонкости прекрасно знают наши специалисты, поэтому демонтаж
                конструкций и зданий лучше заказать у нас. После окончания мы осуществляем
                вывоз строительного мусора самосвалами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Что предлагаем мы?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж конструкций – одна из услуг нашей компании. Мы обслуживаем
                физических и юридических лиц с Москвы и области. Мы занимаем лидирующие
                позиции в данном сегменте рынка и продолжаем активно развиваться. Мы
                закрепили за собой репутацию надежного предприятия, круг постоянных
                клиентов которого регулярно расширяется. Благодаря штату профессионалов мы
                справляемся с задачами любой сложности. Наличие инновационной техники,
                самосвалов позволяет нам проводить все в сжатые сроки. У нас выгодные
                расценки на перечень предоставляемых услуг, а для крупных заказчиков
                делаем скидки. Мы гарантируем качество демонтажа, в чем уже успели
                убедиться наши заказчики с разных регионов страны.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы проведения работ</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Разработка и утверждение клиентом проекта будущих мероприятий**.
                  Согласование документов, разработка и согласование сметы затрат.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Организация строительной площадки**. Подготовка участка к дальнейшим
                  мероприятиям. Ограждение участка, организация доступа на строительную
                  площадку, освещения и пр. Снос временных построек. Организация движения
                  по объекту.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Процессы по отключению коммуникационных систем**. Согласование с
                  необходимыми организациями (разными эксплуатирующими инженерные
                  коммуникации фирмами).
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Подготовка конструкции**. Очистка от элементов интерьера, отделки и
                  пр.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}> **Снос конструкции**.</span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Сортировка образовавшегося в ходе работ мусора**.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Погрузка отходов на самосвалы и вывоз на место утилизации**.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Утилизация мусора**. Переработка бетона, боя кирпича и прочих отходов.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Оформление исполнительных документов, представление и сдача итогового
                  акта мероприятий**.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Мы гарантируем соблюдение требований государственных стандартов, СНиПов,
                требований пожарных и экологических стандартов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества нашей компании</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж здания и его фундамента, конструкций проводится только с
                использованием экскаваторов-разрушителей с навесными установками,
                различных экскаваторов, самосвалов со значительным объемом кузова,
                установок для ручной разборки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Наши клиенты получают следующие выгоды сотрудничества:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Проведение работ исключительно в соответствии с действующими планами и
                проектами.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Составление сметы.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Оформление требуемых разрешений, согласований, документов на снос.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Современный парк техники.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Работа круглосуточно.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Вывоз строительного мусора, утилизация.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Быстрое выполнение работ.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Гарантия на выполненные нашими сотрудниками работы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Стоимость услуг</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>**Цена услуги - от 250 руб/м³**</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Оценочная стоимость демонтажа фундамента и других конструкций складывается
                из следующих факторов:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- Техническое состояние объекта.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Согласно подробному проекту технических работ, рассчитывается
                необходимый объем, определяется время использования установки,
                спецтехники. Также определяется, какая техника необходима во время работ и
                сколько раз её надо будет эксплуатировать.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Строительные отходы классифицируются на определенные категории и
                подвергаются утилизации с учетом этого.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Условные цены работ по демонтажу фундаментов, конструкций варьируются из
                расчета стоимости за квадратный метр или за объект полностью в зависимости
                от объема мероприятий и индивидуальных пожеланий заказчика.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если хотите узнать точную стоимость работ, свяжитесь с нашими менеджерами.
                Они расскажут о деталях сотрудничества, ответят на интересующие вопросы.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog25",
        title: "Демонтаж промышленных зданий в Москве",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж промышленных зданий требует особого подхода, отличного от сноса
                жилых зданий. Это сложная и ответственная задача, которая должна
                выполняться только специализированными организациями с необходимой
                техникой и квалифицированными специалистами.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Процесс демонтажа промышленных зданий</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> Визуальный и инструментальный осмотр</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед началом работ проводится детальное обследование здания. В некоторых
                случаях, кроме визуального осмотра, используется специализированное
                оборудование для оценки состояния конструкций и определения наиболее
                безопасных методов демонтажа.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong> Разработка плана демонтажа</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разрабатывается подробный план работ, учитывающий особенности конструкции
                здания, материалы, из которых оно построено, а также окружающую
                инфраструктуру.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> Получение необходимой документации</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Необходимо получить все разрешения и согласования от соответствующих
                органов, чтобы провести демонтаж в соответствии с законодательными и
                экологическими нормами.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <strong> Подготовка к демонтажу</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Здание подготавливается к демонтажу, включая отключение всех коммуникаций
                (электричество, вода, газ и т.д.), удаление легковоспламеняющихся
                материалов и организация безопасной зоны вокруг объекта.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <strong> Механизированный демонтаж</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Основные работы выполняются с использованием тяжелой техники:
                экскаваторов, кранов, гидромолотов и других специализированных машин. В
                некоторых случаях применяются взрывные работы, требующие особого подхода и
                квалификации специалистов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <strong> Ручная разборка</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                После завершения основных работ проводится ручная разборка оставшихся
                элементов конструкции для минимизации рисков и более тщательной утилизации
                материалов.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={7}>
              <li>
                <strong> Вывоз строительного мусора</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все образовавшиеся в ходе демонтажа отходы сортируются и вывозятся на
                специализированные полигоны для дальнейшей переработки или утилизации.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={8}>
              <li>
                <strong> Оформление отчетной документации</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                По завершении работ оформляются и передаются все необходимые документы в
                соответствующие органы, подтверждающие корректное выполнение демонтажа и
                соблюдение всех норм и требований.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog26",
        title: "Снос зданий повышенной сложности",
        date: "05.10.2024",
        text: (<>
            <p>
              <strong>Особенности сноса сложных зданий</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Снос зданий повышенной сложности требует особого подхода и детального
                планирования. В зависимости от материалов, из которых построено здание, и
                его конструктивных особенностей, используются различные методы и техника.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Этапы работ</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Получение документации**: Получение всех необходимых разрешений и
                  согласований.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Подготовка здания**: Отключение коммуникаций, удаление
                  легковоспламеняющихся материалов, организация безопасной зоны.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Механизированный демонтаж**: Основные работы с использованием тяжелой
                  техники.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Ручная разборка**: Завершающие работы, включая разбор оставшихся
                  конструкций.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Вывоз мусора**: Сортировка и вывоз строительных отходов.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Оформление документации**: Передача всех необходимых документов в
                  соответствующие органы.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Почему выбрать нас?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Профессионализм**: Наши специалисты имеют огромный опыт и необходимые
                квалификации для выполнения сложных демонтажных работ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Современная техника**: Мы используем только современное и надежное
                оборудование, что позволяет выполнять работы быстро и безопасно.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Комплексный подход**: Мы предоставляем полный спектр услуг, от
                разработки плана до вывоза мусора и оформления документации.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Доступные цены**: Мы предлагаем конкурентоспособные цены на все виды
                демонтажных работ.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если вам необходим демонтаж промышленных зданий в Москве, наша компания
                готова предложить вам свои услуги. Мы гарантируем качественное выполнение
                всех работ в сжатые сроки с соблюдением всех норм и требований. Свяжитесь
                с нами для получения консультации и расчета стоимости демонтажа.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog27",
        title: "Требования безопасности при демонтаже зданий",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Безопасность при демонтаже зданий является приоритетной задачей.
                Соблюдение всех необходимых мер предосторожности обеспечивает защиту жизни
                и здоровья работников, предотвращает повреждение оборудования и
                минимизирует риск аварийных ситуаций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Подготовительные меры</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Ограждение и знаки</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Перед началом работ по периметру опасной территории необходимо установить
                ограждения и разместить специальные знаки, предупреждающие о проведении
                демонтажных работ. Это помогает ограничить доступ посторонних лиц на
                территорию и обеспечить безопасность.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Обследование объекта</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                До начала демонтажа проводятся обследования объекта для выявления
                аварийных участков. Это позволяет разработать схему размещения страховки и
                определить зоны повышенного риска.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Инструктаж по технике безопасности</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все работники, допущенные к демонтажу, должны пройти инструктаж по технике
                безопасности. Машинисты спецтехники дополнительно проходят медицинский
                осмотр.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Основные требования по охране труда</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Отключение коммуникаций</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Демонтаж строения можно начинать только после полного отключения объекта
                от всех коммуникаций (электричество, газ, вода и т.д.). Это предотвращает
                риск возникновения аварий и пожаров.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Утвержденная документация</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разрушение сооружений должно осуществляться в соответствии с утвержденной
                документацией и паспортом объекта. Это гарантирует соблюдение всех
                необходимых мер безопасности и предотвращение непредвиденных ситуаций.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Средства индивидуальной защиты</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все работники на площадке демонтажа должны использовать средства
                индивидуальной защиты, такие как каски, перчатки, защитные очки и т.д.
                Нахождение на территории без этих средств категорически запрещено.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Управление строительным мусором</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Строительный мусор, образующийся в ходе поэтапной разборки здания, должен
                спускаться с использованием специальных технических приспособлений и
                контейнеров. Это предотвращает загрязнение территории и снижает риск
                травмирования работников.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Дополнительные меры предосторожности</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Обучение персонала</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Регулярное обучение и проверка знаний персонала по технике безопасности и
                правилам работы с оборудованием являются важной частью обеспечения
                безопасности на строительной площадке.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Контроль за состоянием техники</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все используемые машины и оборудование должны регулярно проходить
                технический осмотр и обслуживание. Это помогает избежать поломок и аварий,
                связанных с неисправностью техники.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Планирование и координация работ</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разработка подробного плана работ с учетом всех этапов демонтажа и
                распределение обязанностей среди работников способствует более
                эффективному и безопасному выполнению задач.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Соблюдение всех перечисленных мер безопасности при демонтаже зданий
                гарантирует защиту здоровья и жизни работников, а также обеспечивает
                успешное выполнение всех этапов работ без происшествий. Компания,
                выполняющая демонтаж, должна строго следовать всем нормативам и правилам,
                обеспечивая высокий уровень безопасности на объекте.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog28",
        title: "Утилизация отходов",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Учёные прогнозируют, что через 30 лет человечество будет производить не
                менее трёх с половиной миллиардов тонн твёрдых бытовых отходов (ТБО)
                ежегодно. Планета рискует утонуть в мусоре в ближайшие десятилетия, если
                срочно не начать принимать меры. Люди должны учиться правильно избавляться
                от мусора, чтобы сохранить свой общий дом в чистоте. Рассмотрим, как
                утилизируют отходы, какие экологически безопасные методы переработки
                используют частные лица и организации.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Цели утилизации мусора: почему это важно?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Чтобы постепенно уменьшать свалки и количество отходов, их нужно правильно
                утилизировать, то есть собирать, транспортировать на полигоны,
                захоранивать или перерабатывать. Быстрое уничтожение мусора преследует
                такие цели, как:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Сохранение здоровья жителей планеты** — риски отравления и серьёзных
                заболеваний из-за токсинов при экологической утилизации сводятся к
                минимуму;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Снижение производственных затрат предприятий и получение прибыли** за
                счёт переработки стекла, металла, пластика, бумаги, их повторного
                использования;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Сохранение окружающей среды** — без правильной утилизации отходы могут
                разлагаться годами и десятилетиями. Токсичные выделения загрязняют
                атмосферу, гидросферу, наносят природе огромный урон.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Эффективные способы избавления от мусора направлены на его полную
                ликвидацию или переработку. Переработка ставит перед собой несколько
                задач, среди которых:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>- производство новой продукции;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - рекуперация — извлечение ценных веществ для повторного использования;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - регенерация отходов до уровня вторичного сырья;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - рециклинг — повторное использование по прямому назначению (например,
                металлолом идет на производство металлических изделий). Рециклингу
                подлежат стекло, ткани, бумага, бетон, полимерные материалы.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Проведение утилизации отходов регламентируется законодательством на
                федеральном уровне. Выбор способа зависит от типа мусора, класса его
                опасности для среды и человека.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Методы утилизации мусора</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Каждая стихийно возникшая и разросшаяся свалка — это стихийное бедствие,
                глобальная проблема человечества. Во всем мире используют эффективные
                способы борьбы с отходами. Рассмотрим каждый метод подробнее.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Захоронение или складирование на специальных полигонах</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Это простой и дешевый способ утилизации, который предусматривает
                равномерное распределение отходов под слоями грунта. Российская
                экологическая реформа от 2019 года поставила своей целью сокращение
                количества мусорных полигонов в пользу вторичной переработки.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Вторая «жизнь» отходов: цели и способы переработки</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вопрос о том, как можно сократить количество отходов, неизменно волнует
                учёных. ТБО и другим видам мусора дают «вторую» жизнь. Из отходов создают
                новые полезные продукты. Переработка — направление затратное, но
                экологически безопасное.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Что можно сдать как вторсырьё? Это бумага, стекло, пластик, металлы
                (алюминий), строительные материалы, резина, древесные отходы, мебель,
                техника, обувь и одежда, автомобильные запчасти, электронные устройства.
                Если пищевой мусор разлагается очень быстро (от двух до шести месяцев), то
                перечисленные — наоборот, долго. Например, на разложение стекла уходит
                более тысячи лет, а пластик может гнить не один век. Металлы разлагаются
                до 500 лет, накапливаясь на полигонах.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Разные виды вторсырья для переработки сдают в специальные пункты приёма.
                Из них производят скамейки, туалетную бумагу, делают упаковку и покрытия
                для детских площадок. Вопрос о том, как использовать вторсырьё, всё чаще
                встаёт перед крупными компаниями. Процессы переработки дают финансовую и
                экологическую выгоду. Влияние на экосистемы сводится к минимуму. Выделим
                основные методы вторичной переработки:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Механический** — измельчение путём дробления и прессование. Это
                эффективный способ переработки вторсырья. Он помогает ответить на вопрос о
                том, как сократить количество бытовых отходов. ТБО дробят и прессуют в
                брикеты под высоким давлением.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Химический** — этот метод подскажет, как переработать вторсырьё, если
                нужно утилизировать жидкие нефтепродукты. Он заключается в изменении
                состава за счёт добавления нейтральных веществ.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Термический** — для выработки тепловой энергии.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Биологический** — о том, как сделать компост из пищевых отходов, знают
                российские аграрии. Суть метода в преобразовании органических соединений в
                удобрения. Отходы разлагаются под слоем почвы. В результате
                сельскохозяйственники получают компост для удобрения растений. Теперь вы
                знаете, как перерабатывают пищевые отходы с пользой для аграрной отрасли.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Переработка отходов как вторсырьё приносит пользу в быту, строительстве,
                промышленности, сельском хозяйстве. На компостирование приходится около 6
                % от всего мусора, который перерабатывают в РФ. Но этот метод является
                одним из самых экологичных.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog29",
        title: "Утилизация строительного мусора",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Утилизация строительного мусора осуществляется двумя способами.
                Неперерабатываемые материалы поступают на полигон, где происходит их
                захоронение. Материалы, подлежащие переработке (например, бой кирпича,
                бетона, части металлоконструкций) поступают на сортировку и далее — на
                предприятия переработки. В результате из них получают полезное вторичное
                сырье, которое можно использовать, например, в строительстве дорог
                (кирпичная крошка).
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Правила вывоза и уборки строительного мусора после пожара</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз мусора после пожара и уборка территории должны быть выполнены в
                соответствии с определенными правилами, направленными на безопасное и
                чистое окружение.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Правила уборки строительного мусора после пожара</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong> Проведение инспекции</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Первое правило - необходимо провести инспекцию места пожара и оценить
                общее состояние здания, а также убедиться в том, что нет опасности для
                жизни и здоровья людей.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <strong> Очистка территории</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Очистить область от любых оставшихся осколков и острых предметов, которые
                могут привести к травмам или порезам.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <strong> Определение вида и объема мусора</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Необходимо определить, какого рода и какой объем строительного мусора
                должен быть вывезен. Если это обычный строительный мусор, он может быть
                удален посредством обычных средств, таких как контейнеры для мусора,
                рабочие и транспортные машины, погрузчики или самосвалы.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <strong> Удаление опасных материалов</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если пожар был вызван определенными веществами, такими как асбест, то
                вывоз этого мусора должен быть выполнен специально обученными
                сотрудниками. Они должны иметь необходимые средства защиты и приборы для
                контроля загрязнения окружающей среды.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <strong> Проверка природных ресурсов</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Необходимо убедиться в том, что все природные ресурсы в районе пожара
                остались незагрязненными. Если были использованы химические вещества в
                процессе строительства или пожара, то необходимо проверить и почистить все
                возможные источники загрязнения, включая землю, подземную воду и скважины.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <strong> Организация работ</strong>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз и уборка строительного мусора после пожара должны быть выполнены с
                помощью опытных и организованных специалистов. Они должны соблюдать все
                необходимые меры предосторожности и осторожности, чтобы убедиться в том,
                что все оставшиеся острые предметы, опасные вещества и мусор корректно
                удалены, а окружающая среда осталась безопасной для всех.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                В целом, правила вывоза и уборки строительного мусора после пожара должны
                соблюдаться строго, чтобы минимизировать риски для здоровья людей и
                загрязнения окружающей среды. Пожары - серьёзные катастрофы, которые могут
                привести к необратимым последствиям, поэтому необходимо принять все меры
                предосторожности, чтобы защитить себя и окружающую среду.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog30",
        title: "Основные правила по вывозу снега",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                С наступлением зимы главной задачей коммунальщиков становится оперативная
                уборка снега с городских улиц и дорог. Особые требования предъявляются к
                очистке дорог в зимнее время, так как снег и гололедные явления могут
                привести к авариям и пробкам.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Категории дорог</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Все дороги делятся на следующие категории:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **IA** — магистральные скоростные дороги с интенсивным движением;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **IБ** — городские магистральные дороги и улицы с регулируемым
                движением;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **IВ** — магистральные дороги районного значения;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **II** — транспортно-пешеходные дороги;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **III** — дороги и улицы местного значения и основные дворовые проезды;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>- **IV** — дороги жилой застройки;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **V** — дороги с односторонним движением.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Техника для уборки снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Вывоз снега осуществляется на снеготвалы или снегоплавильные станции. К
                уборке снега привлекают снегуборочные машины, погрузчики и прочую
                специальную технику с навесным оборудованием.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Нормативы содержания территорий</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Нормативы содержания городских территорий, тротуаров, дорог и
                автомагистралей регламентируются требованиями ГОСТ Р 50597-2017 «Дороги
                автомобильные и улицы», а также инструкциями, руководствами и правилами
                местных органов самоуправления с учетом климатических особенностей
                регионов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Очистка дорог</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Очистка дорог должна начинаться сразу после окончания снегопадов. В случае
                обильных и длительных осадков техника выводится на улицы и автомагистрали
                раньше. Снег с проезжей части дорог должен убираться до асфальтового
                покрытия. Ровный слой снежного покрытия допускается на второстепенных
                дорогах с интенсивностью движения менее 1500 автомобилей в сутки. Снежные
                валы можно устраивать в местах, где они не мешают движению пешеходов и
                автотранспорта. Снег с обочин должен вывозиться в течение 6-12 суток после
                окончания снегопадов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Очистка тротуаров</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Сроки уборки снега с тротуаров и придомовых территорий зависят от
                интенсивности движения пешеходов или велосипедистов:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - 1 час — при интенсивности от 250 чел/час;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - 2 часа — при интенсивности 100-250 чел/час;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - 3 часа — при количестве пешеходов до 100 чел/час.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                В первую очередь убираются дороги и тротуары с большей интенсивностью
                движения. Основная работа по расчистке дорог и тротуаров должна
                производиться ранним утром, в вечернее и ночное время.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности вывоза снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Снежные массы с городских улиц и дорог вывозятся на снеговые отвалы и
                снегоплавильные станции. Полигоны для складирования снега размещают на
                удалении от населенных пунктов. Недостатками таких свалок являются
                длительность естественного таяния снега и невозможность качественной
                очистки сточных вод. Наиболее безопасным для природы и экономически
                выгодным вариантом является утилизация снега на мобильных или стационарных
                снегоплавильных станциях.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Комплексы снегоплавильных станций</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Такие комплексы оборудуются:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - дробилками для измельчения льда и твердого снега;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - плавильными камерами для таяния снежных масс;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - решетками и сетками для сбора крупного мусора;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - песколовками для улавливания песка и мелких камней;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - фильтрами для очистки стоков от вредных примесей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Снег растапливается отработанной теплой водой промышленных предприятий,
                ТЭЦ или нагретой водой электрических, газовых или дизельных установок.
                Очищенная талая вода удаляется в ливневую канализацию.
              </span>
            </p>
            <p>
              <br />
              <br />
            </p>
            <p>
              <strong>Организация вывоза снега: основные правила и техника</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Зачем нужна утилизация снега?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                С наступлением зимы коммунальные службы и другие организации сталкиваются
                с необходимостью оперативной уборки снега. Скопление снега может привести
                к следующим проблемам:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Образование огромных сугробов, которые могут таять до весны, что
                приведет к наводнениям и повреждению ливневых канализаций.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Уплотнение и замерзание снега под собственным весом, превращение его в
                ледяные глыбы, которые труднее убирать.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Накопление в снегу вредных веществ, что приводит к его токсичности и
                загрязнению почвы.
              </span>
            </p>
          </>
          ),
    },
    {
        id: "blog31",
        title: "Нормативные требования",
        date: "05.10.2024",
        text: (<>
            <p>
              <span style={{ fontWeight: 400 }}>
                Регламент вывоза снега прописан в следующих документах:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - ГОСТ Р 50597-2017 «Дороги автомобильные и улицы. Требования к
                эксплуатационному состоянию»
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - СанПиН 2.1.7.3550-19 «Санитарно-эпидемиологические требования к
                содержанию территорий муниципальных образований»
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Запрещается:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Складировать снег на автомагистралях и скоростных дорогах.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Оставлять снег на разделительной полосе шириной менее 5 м.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Сбрасывать снежные сугробы вблизи железнодорожных переездов,
                перекрестков, тротуаров, газонов, мостов.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Организация вывоза снега</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Проверка территории**: Убедитесь, что территория, на которой будут
                  проводиться работы по погрузке снега, позволяет проехать самосвалам и
                  специализированной технике. Для этого можно произвести замеры.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={2}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Выбор техники**: Определите, какая техника потребуется для проведения
                  всех необходимых работ.&nbsp;
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={3}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Расчет объема снега**: Измерьте объем снега, который требуется
                  вывезти.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={4}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Оповещение автовладельцев**: Если уборка снега планируется около жилых
                  домов, следует оповестить всех автовладельцев о проведении этой
                  процедуры или выбрать время вывоза снега таким образом, чтобы
                  припаркованных машин на стоянке не было.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={5}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Сбор снега в кучи**: Желательно заранее собрать снег в кучи, которые
                  будет удобно погрузить в авто. Это позволит сэкономить время. Осмотрите
                  территорию, на которой будут проводиться работы по уборке снега, и
                  обозначьте все «трудные» места: люки, бордюры, неровности, узкие
                  участки.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start={6}>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Ограждение площадки**: Площадку, на которой будут проводиться работы,
                  следует оградить, чтобы на территорию никто не заезжал и не заходил.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Техника для вывоза снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Для эффективного вывоза снега используют следующие виды техники:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Снегопогрузчики**: Для сбора снега в кучи и его погрузки на
                транспортные средства.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Самосвалы**: Для транспортировки снега на снегоплавильные станции или
                полигоны для складирования.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - **Снегоплавильные установки**: Для утилизации снега. Они могут быть
                мобильными или стационарными и оборудуются дробилками для измельчения льда
                и твердого снега, плавильными камерами для таяния снежных масс, решетками
                и сетками для сбора крупного мусора, песколовками для улавливания песка и
                мелких камней, фильтрами для очистки стоков от вредных примесей.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение договора на утилизацию снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Для утилизации снега необходимо:</span>
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Заключить договор с компанией, предоставляющей услуги по утилизации
                  снега.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Посчитать объем снега, который нужно утилизировать.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Получить талоны на утилизацию в требуемом количестве.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}> Приехать на пункт утилизации.</span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Организация вывоза снега и его утилизация — важный процесс, который
                требует четкого планирования и использования специальной техники.
                Соблюдение нормативных требований и привлечение профессионалов обеспечит
                безопасность и чистоту городских территорий в зимний период.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Организация вывоза снега с помощью самосвалов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Выбор самосвалов для вывоза снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Самосвалы являются популярной техникой для перевозки снега. Для этих целей
                обычно используют машины с вместимостью кузова не менее 18 кубометров и
                грузоподъемностью около 15 тонн. При использовании таких самосвалов за
                один рейс можно транспортировать примерно 25-30 кубометров снега (10-15
                тонн). Однако необходимо учитывать, что вес снега зависит от его плотности
                и влажности:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Сухой снег имеет массу 150-200 кг/кубометр.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Талый снег имеет массу около 550 кг/кубометр.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Выбор колесной формулы самосвала</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                При выборе колесной формулы самосвала следует ориентироваться на
                особенности местности, где планируется уборка снега:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Для очистки снега вдоль многополосных трасс подходят самосвалы с
                колесной базой 4x8 или 8x8.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Для работы во дворах такая техника не подходит, так как не сможет
                разворачиваться на ограниченной территории.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Преимущества профессионального вывоза снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Частые снегопады часто приводят к необходимости заказа услуг по вывозу
                снега. Владельцы частных домов, дач или гаражей знают, что расчистка
                сугробов — трудоемкое занятие, отнимающее много сил и времени. Особенно
                сложно справиться с уборкой больших площадей в одиночку или даже с
                напарником. Оптимальное решение — обратиться к профессионалам.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Особенности профессиональной уборки снега</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Профессиональная уборка снега предлагает ряд преимуществ:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Механизация труда и использование спецтехники, что позволяет проводить
                работы значительно быстрее.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Полная очистка большой территории, даже при наличии снежных масс в
                труднодоступных местах.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                - Сокращение времени и сил, затрачиваемых на уборку.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Процесс профессиональной уборки снега</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Расчистка территории**: Сбор снега в кучи для последующей погрузки.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Погрузка снежных масс**: Использование фронтальных погрузчиков или
                  бульдозеров.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Транспортировка**: Перевозка снега самосвалами.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Утилизация**: Снег вывозится на снегоплавильные станции или полигоны
                  для складирования.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Сдача территории клиенту**: Очистка территории от снега и передача
                  клиенту.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Организация работы</strong>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Проверка территории**: Убедитесь, что территория позволяет проехать
                  самосвалам и специализированной технике. Для этого можно произвести
                  замеры.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Выбор техники**: Определите, какая техника потребуется для проведения
                  всех необходимых работ.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Расчет объема снега**: Измерьте объем снега, который требуется
                  вывезти.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Оповещение автовладельцев**: Если уборка снега планируется около жилых
                  домов, оповестите всех автовладельцев о проведении этой процедуры.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Сбор снега в кучи**: Желательно заранее собрать снег в кучи для
                  удобной погрузки.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  **Ограждение площадки**: Оградите площадку, на которой будут проводиться
                  работы, чтобы на территорию никто не заезжал и не заходил.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Услуги по аренде самосвалов</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Если заказчик желает убрать снег и вывезти его на снегоплавильную станцию
                самостоятельно, можно воспользоваться услугой «аренда самосвала»,
                предоставляемой специализированными компаниями, такими как СпецУстройство.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Заключение</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Правильная организация и использование специальной техники для вывоза
                снега позволяет эффективно решать проблему скопления снега, обеспечивая
                безопасность и чистоту городских территорий в зимний период.
              </span>
            </p>
          </>
          ),
    },
];

export default blogData;