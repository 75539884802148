import React from 'react'

import "./TagsSmall.scss";

const TagsSmall = ({data}) => {
  return (
    <div className='tagsSmall'>
        {data.map((item) => (
            <div key={item.id} className='tagsSmall__item'>
                {item.text}
            </div>
        ))}
    </div>
  )
}

export default TagsSmall