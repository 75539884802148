import React from "react";
import { useState, useEffect } from "react";

// import { Link } from "react-router-dom";
import {
  Button,
  Header,
  HeaderName,
  HeaderGlobalBar,
} from "carbon-components-react";

import BurgerMenuBlock from "./BurgerMenuBlock/BurgerMenuBlock";

import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";

import Logo from "../../../assets/Logo_Specustroystvo2.png"
import phone_call from "../../../assets/phone_call.png"
import whatsapp_call from "../../../assets/whatsapp.png"
import telegram_call from "../../../assets/telegram.png"

import { Menu } from "@carbon/icons-react";

import "./HeaderUI.scss";

const menuNav = [
  {
    menu: "aboutCompany",
    text: "О компании",
    submenu: [
      {
        id: "l11",
        text: "О нас",
        link: "/about",
      },
      {
        id: "l12",
        text: "Менеджмент",
        link: "/managment",
      },
      {
        id: "l13",
        text: "Контакты",
        link: "/contacts",
      },
      {
        id: "l14",
        text: "Лицензии и сертификаты",
        link: "/license",
      },
      {
        id: "l15",
        text: "Награды",
        link: "",
      },
      {
        id: "l16",
        text: "FAQ",
        link: "/faq",
      },
      {
        id: "l17",
        text: "Отзывы",
        link: "/reviews",
      },
      {
        id: "l18",
        text: "Техника с которой работаем",
        link: "/ourTech",
      },
    ],
  },
  {
    menu: "disposal",
    text: "Вывоз и утилизация ",
    submenu: [
      {
        id: "l21",
        text: "Вывоз и утилизация грунта ",
        link: "/services/service_1",
      },
      {
        id: "l22",
        text: "Вывоз бетонного боя в Москве",
        link: "/services/service_2",
      },
      {
        id: "l23",
        text: "Вывоз мусора контейнерами",
        link: "/services/service_4",
      },
      {
        id: "l24",
        text: "Вывоз и утилизация мусора в Москве",
        link: "/services/service_3",
      },
      {
        id: "l25",
        text: "Вывоз снега",
        link: "/services/service_8",
      },
    ],
  },
  {
    menu: "groundWorks",
    text: "Земляные работы",
    submenu: [
      {
        id: "l31",
        text: "Разработка котлованов",
        link: "/services/service_7",
      },
    ],
  },
  {
    menu: "otherServices",
    text: "Другие услуги",
    submenu: [
      {
        id: "l31",
        text: "Песок, щебень, кирпичный бой, асфальтная крошка с доставкой",
        link: "/services/service_5",
      },
      {
        id: "l32",
        text: "Плодородный грунт с доставкой в Москве",
        link: "/services/service_6",
      },
      {
        id: "l33",
        text: "Талоны на утилизацию грунта, мусора",
        link: "/services/service_9",
      },
    ],
  },
];

const menuItems = [
  {
    id: "menuLink_1",
    link: "Главная",
    path: "/",
  },
  {
    id: "menuLink_2",
    link: "О компании",
    menuItems: [
      {
        id: "menuItem_1_1",
        text: "О нас",
        link: "/about",
      },
      {
        id: "menuItem_1_2",
        text: "Менеджмент",
        link: "/managment",
      },
      {
        id: "menuItem_1_3",
        text: "Контакты",
        link: "/contacts",
      },
      {
        id: "menuItem_1_4",
        text: "Лицензии и сертификаты",
        link: "/license",
      },
      {
        id: "menuItem_1_5",
        text: "Награды",
        link: "",
      },
      {
        id: "menuItem_1_6",
        text: "FAQ",
        link: "/faq",
      },
      {
        id: "menuItem_1_7",
        text: "Отзывы",
        link: "/reviews",
      },
      {
        id: "menuItem_1_8",
        text: "Техника с которой работаем",
        link: "/ourTech",
      },
    ],
    nested: false,
  },
  {
    id: "menuLink_3",
    link: "Услуги",
    menuItems: [
      {
        menu: "disposal",
        text: "Вывоз и утилизация ",
        submenu: [
          {
            id: "l21",
            text: "Вывоз и утилизация грунта ",
            link: "/services/service_1",
          },
          {
            id: "l22",
            text: "Вывоз бетонного боя в Москве",
            link: "/services/service_2",
          },
          {
            id: "l23",
            text: "Вывоз мусора контейнерами",
            link: "/services/service_4",
          },
          {
            id: "l24",
            text: "Вывоз и утилизация мусора в Москве",
            link: "/services/service_3",
          },
          {
            id: "l25",
            text: "Вывоз снега",
            link: "/services/service_8",
          },
        ],
      },
      {
        menu: "groundWorks",
        text: "Земляные работы",
        submenu: [
          {
            id: "l31",
            text: "Разработка котлованов",
            link: "/services/service_7",
          },
        ],
      },
      {
        menu: "otherWorks",
        text: "Другие услуги",
        submenu: [
          {
            id: "l31",
            text: "Песок, щебень, кирпичный бой, асфальтная крошка с доставкой",
            link: "/services/service_5",
          },
          {
            id: "l32",
            text: "Плодородный грунт с доставкой в Москве",
            link: "/services/service_6",
          },
          {
            id: "l33",
            text: "Талоны на утилизацию грунта, мусора",
            link: "/services/service_9",
          },
        ],
      },
    ],
    nested: true,
  },
  {
    id: "menuLink_4",
    link: "Цены",
    path: "/price",
  },
  {
    id: "menuLink_5",
    link: "Проекты",
    path: "/projects",
  },
  {
    id: "menuLink_5",
    link: "Блог",
    path: "/blogs",
  },
];

const HeaderUI = () => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const burgerSubMenus = document.getElementsByClassName("headerBurgerSubMenu");

  const changeBurgerMenuState = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
    if (!burgerMenuOpen) {
      for (var i = 0; i < burgerSubMenus.length; i++) {
        burgerSubMenus[i].classList.remove("headerBurgerSubMenu-open");
      }
    } else {
    }
  };

  const openPopUp = () => {
    document.getElementById("PopUp").classList.toggle("popUp-open");
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 1568) {
        setBurgerMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <header className="headerWrapper">
      <div className="header">
        <button className="headerBurgerBtn" onClick={changeBurgerMenuState}>
          <Menu />
        </button>
        <img src={Logo} alt="logo" className="header__logo" />
        <HeaderNavigation menuItems={menuItems} />
        <HeaderGlobalBar className="headerGlobal">
          <a href="tel:+79257010017" className="header__callBtn">
            <img
              src={phone_call}
              alt="phone_call"
              className="header__callBtn__img"
            />
          </a>
          <a href="https://wa.me/79257010017" className="header__callBtn">
            <img
              src={whatsapp_call}
              alt="phone_call"
              className="header__callBtn__img"
            />
          </a>
          <a href="https://t.me/Sy_manager" className="header__callBtn">
            <img
              src={telegram_call}
              alt="phone_call"
              className="header__callBtn__img"
            />
          </a>
          <Button className="header__calcBtn" onClick={openPopUp}>
            Рассчитать стоимость
          </Button>
        </HeaderGlobalBar>
        {burgerMenuOpen && (
          <div
            className="headerBurgerMenu headerBurgerMenu-open"
            id="burgerMenu"
          >
            <a className="header__burgerLink" href="/">
              Главная
            </a>
            <div className="vertLine vertLine-burger"></div>
            {menuNav.map((item) => (
              <BurgerMenuBlock {...item} key={item.menu} />
            ))}
            <a className="header__burgerLink" href="/price">
              Цены
            </a>
            <a className="header__burgerLink" href="/projects">
              Проекты
            </a>
            <a className="header__burgerLink" href="/blogs">
              Блог
            </a>
          </div>
        )}
        <div
          className={
            burgerMenuOpen
              ? "headerFadeScreen headerFadeScreen-open"
              : "headerFadeScreen"
          }
          id="headerFadeScreen"
        ></div>
      </div>
    </header>
  );
};

export default HeaderUI;
