import service_1_mainBlock from "../assets/Service_1/service_1_mainBlock.png";
import service_2_mainBlock from "../assets/Service_2/service_2_mainBlock.png";
import service_3_mainBlock from "../assets/Service_3/service_3_mainBlock.png";
import service_4_mainBlock from "../assets/Service_4/service_4_mainBlock.png";
import service_5_mainBlock from "../assets/Service_5/service_5_mainBlock.png";
import service_6_mainBlock from "../assets/Service_6/service_6_mainBlock.png";
import service_7_mainBlock from "../assets/Service_7/service_7_mainBlock.png";
import service_8_mainBlock from "../assets/Service_8/service_8_mainBlock.png";
import service_9_mainBlock from "../assets/Service_9/service_9_mainBlock.png";
import aboutService_img_1_1 from "../assets/Service_1/aboutService_img_1.png";
import aboutService_img_2_1 from "../assets/Service_2/aboutService_img_1.png";
import aboutService_img_2_2 from "../assets/Service_2/aboutService_img_2.png";
import aboutService_img_3_1 from "../assets/Service_3/aboutService_img_1.png";
import aboutService_img_3_2 from "../assets/Service_3/aboutService_img_2.png";
import aboutService_img_4_1 from "../assets/Service_4/aboutService_img_1.png";
import aboutService_img_4_2 from "../assets/Service_4/aboutService_img_2.png";
import aboutService_img_5_1 from "../assets/Service_5/aboutService_img_1.png";
import aboutService_img_5_2 from "../assets/Service_5/aboutService_img_2.png";
import aboutService_img_6_1 from "../assets/Service_6/aboutService_img_1.png";
import aboutService_img_6_2 from "../assets/Service_6/aboutService_img_2.png";

export const aboutService_data = [
  {
    id: "service_1",
    mainBlock__data: {
      title: "Вывоз и утилизация грунта ",
      text: (
        <>
          Вывоз и утилизация грунта с погрузкой <br />
          Вывоз и утилизация грунта без погрузки <br />
          Договор на вывоз грунта <br />
          от 12000 руб. за м³
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Вывоз и утилизация грунта",
        text: (
          <p>
            Вывоз и утилизация грунта Компания ООО Спецустройство производит{" "}
            <u>вывоз грунта в Москве и Московской области</u> материалов: песок,
            крошка асфальта, нерудные материалы, остатки кирпичей, бетон, щебень
            и другие сыпучие материалы с территории клиента.{" "}
            <u>Осуществляем транспортировку отходов строительства</u>,
            замусоренного грунта в любых объёмах с площадок, квартир и дач.{" "}
            <u>Вывозим и утилизируем строительный мусор.</u>
            Цены на вывоз за м³ формируются индивидуально. Стоимость вывоза
            грунта формируется с учетом выезда на объект. Сотрудничество с нами
            имеет ряд преимуществ: <br />
            — индивидуальный подход к каждому клиенту; <br />
            — необходимая спецтехника всегда в наличии; <br />— оперативно
            осуществляем вывоз грунта, благодаря своему автопарку. <br />
            Мы предоставляем спецтехнику в любую точку Москвы и Московской
            области. Вывозим грунт с погрузкой и транспортировкой любых объемов.
            В распоряжении <u>собственный автопарк с самосвалами</u>.
            Предоставляем только исправные машины, которыми управляют
            профессиональные и опытные водители, чтобы процесс вывоза грунта
            самосвалами был быстрым и эффективным. <br />
            Наш многолетний опыт позволяет нам профессионально проводить
            разработку, вывоз грунта строительного мусора, отходов и земли по
            низким ценам. <br />
            Окончательную стоимость уточняйте по <u>телефону</u> или можете{" "}
            <u>отправить заявку через форму и получить расчет.</u> <br />
            Заключаем договор на вывоз земли, на перевозку плодородного грунта.
            Условия, сроки прописываются в договоре. <br />
            Остались вопросы? Сколько будет стоить? Как заказать вывоз грунта и
            разработку котлована ответят наши специалисты
          </p>
        ),
      },
      {
        id: "aboutService_2",
        title: "Как мы работаем",
        text: (
          <p>
            Заявка <br />
            Заявка с указанием требований и пожеланий <br />
            Расчет <br />
            Расчет и согласование стоимости услуг <br />
            График <br />
            Определяем последовательность работ, количество рабочих и ресурсов
            для выполнения <br />
            Договор <br />
            Согласовываем детали, заключаем договор <br />
            Работа <br />
            Проводим работы, контроль персональным менеджером <br />
            Отчет <br />
            Отчетная документация
          </p>
        ),
        img: aboutService_img_1_1,
        textRight: true,
      },
      {
        id: "aboutService_3",
        title: "Как выбрать подрядчика для вывоза и утилизации грунта",
        text: (
          <>
            <p>
              При выборе подрядчика для вывоза и утилизации грунта рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_2",
    mainBlock__data: {
      title: "Вывоз бетонного боя в Москве",
      text: (
        <>
          Вывозим и утилизируем на одобренные полигоны в Московской области{" "}
          <br />
          Кратчайшее время прибытия на объект <br />
          Соблюдение сроков <br />
          Любые виды оплаты для юридических лиц (УСН и НДС)
          <br />
          от 280 руб. за м³
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: " Вывоз бетонного боя ",
        text: (
          <p>
            На любых стройплощадках часто накапливается разного рода мусор:
            бетонный бой, бой кирпича и кирпичный скол, куски боя кирпича,
            арматура, стекло, куски боя бетона. Иногда им засыпают дороги,
            используют для мягкой почвы или выравнивания поверхности участка. Но
            чаще весь этот мусор вывозят на свалку. <br />
            Самостоятельно <u>вывозить строительный мусор</u> достаточно сложно.
            К тому же, необходимо не просто избавиться от строительных отходов,
            но и произвести утилизацию ЖБИ должным образом. Обычно такого рода
            мусор вывозится на специальные полигоны. Для этого необходимо
            обращаться в компанию, которая профессионально осуществляет вывоз
            ЖБИ отходов. <br />
            Наша компания занимаемся вывозом боя бетона, кирпича и любых
            строительных отходов с их последующей утилизацией на специальных
            полигонах. <br />
            Мы располагаем <u>собственным автопарком спецтехники</u> для вывоза
            мусора. <br />
            Сделать заказ можно через наш сайт или позвонив по номеру телефона в
            шапке сайта. Наши менеджеры ответят на ваши вопросы. Наши
            специалисты помогут рассчитать стоимость услуг, после чего будут
            оговорены дата и время. <br />В назначенный срок, сотрудники нашей
            компании подъедут и загрузят весь бетонный бой
          </p>
        ),
        img: aboutService_img_2_1,
      },
      {
        id: "aboutService_2",
        title: "Утилизация бетонного боя",
        text: (
          <p>
            Вывоз строительного мусора и ЖБИ производится на специальные
            полигоны по заключенному договору на обслуживание. <br />
            Выше вы можете ознакомиться с ценами на вывоз. <br />
            Однако обращаем ваше внимание на то, что окончательную цену вам
            скажет наш сотрудник. Поскольку сами вы можете ошибиться в объеме
            мусора для вывоза. <br />
            Мы профессионально рассчитаем необходимый транспорт и стоимость,
            чтобы быстро и качественно вывезти весь мусор. <br />И оставить ваш
            участок в полной чистоте.
          </p>
        ),
        img: aboutService_img_2_2,
        textRight: true,
      },
      {
        id: "aboutService_3",
        title: "Как выбрать подрядчика для утилизации бетонного боя в Москве?",
        text: (
          <>
            <p>
              При выборе подрядчика для утилизации бетонного боя рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_3",
    mainBlock__data: {
      title: "Вывоз и утилизация мусора в Москве",
      text: (
        <>
          Вывоз и утилизация мусора спецтехникой и контейнерами в Москве и
          Московской области. <br />
          По низкой цене. <br />
          Вывоз круглосуточно в любых объемах. <br />
          Гарантия соблюдения сроков, указанных в договоре. <br />
          Работаем с физическими и юридическими лицами. Предоставляем все
          необходимые документы. <br />
          от 400 руб. за м
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Вывоз и утилизация мусора в Москве",
        text: (
          <p>
            Быстро и качественно вывезем и утилизирует мусор в любых объемах, с
            любых территории, офисов, домов или квартир в Москве и Московской
            области. <br />
            Работаем без выходных, строго по графику. <br />
            Выполним поставку автомобиля с вывозом срочно, в случае экстренной
            необходимости.
            <br />
            Демонтаж разных конструкций по вывозу твердых коммунальных отходов.
            <br />
            Вывоз КГМ Вывоз старой мебели из квартиры <br />
            Уборка территории без вывоза <br />
            Ручную уборку по вывозу <br />
            Ручную погрузку с вывозом <br />
            Контейнер под мусор <br />
            Вывоз строительного мусора <br />
            Газель под мусор <br />
            Предоставляем грузчиков при вывозе <br />
            Вывоз из квартиры <br />
            Установка бункера под мусор, бытовых отходов <br />
            Вывоз твердых бытовых отходов <br />
            Установка контейнера под мусор без вывоза <br />
            Есть все для вывоза газелью <br />
            Работа в мусоре с ПУХТО
            <br />
          </p>
        ),
        img: aboutService_img_3_1,
      },
      {
        id: "aboutService_2",
        title: "Что нужно учитывать при вывозе и утилизации мусора?",
        text: (
          <p>
            Мы принимаем заказы от юридических организаций и частных лиц,
            промышленных предприятий, строительных компаний, торговых центров,
            магазинов, гостиниц и ресторанов. Стоимость услуги погрузки напрямую
            зависит от типа отходов, объема, территориальной удаленности,
            срочности заказа и необходимого транспорта. <br />
            Также важную роль играет то, на какой полигон будет вывезен мусор.{" "}
            <br />
            ООО Спецустройство имеет в распоряжении <u>спецтехнику</u>, машины,
            мусоровозы и все виды необходимых конструкций. <br />
            Также вы можете <u>заказать мусорные контейнеры</u> объемом 8 м³, 20
            м³ и 27 м³.
          </p>
        ),
        img: aboutService_img_3_2,
        textRight: true,
      },
      {
        id: "aboutService_3",
        title: "Как выбрать подрядчика для вывоза и утилизации мусора",
        text: (
          <>
            <p>
              При выборе подрядчика для вывоза и утилизации мусора рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_4",
    mainBlock__data: {
      title: "Вывоз мусора контейнерами",
      text: (
        <>
          Вывоз и утилизация мусора спецтехникой и контейнерами в Москве и
          Московской области. <br />
          По низкой цене. <br />
          Вывоз круглосуточно в любых объемах. <br />
          Гарантия соблюдения сроков, указанных в договоре. <br />
          Работаем с физическими и юридическими лицами. Предоставляем все
          необходимые документы. <br />
          от 400 руб. за м
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Вывоз мусора контейнерами",
        text: (
          <p>
            Наиболее удобным вариантом для сбора мусора являются контейнеры от
            компании ООО Спецустройство, которые предназначены специально для
            временного хранения и транспортировки любых видов отходов. <br />
            Контейнеры для мусора в Москве разработан из прочного материала,
            который устойчив к негативному воздействию внешней среды, легко
            поддается чистке и мойке, а также имеет небольшой вес. <br />
            В зависимости от типа и объема мусора, используются мусорные
            контейнеры с определенной вместимостью под утилизацию мусора: <br />
            8 м³ – отличаются грузоподъемностью до 5-ти тонн и подходят для
            сбора отходов средних размеров, кирпича. <br />
            20 м³ – контейнеры предназначены для сбора крупногабаритного и
            тяжелого мусора с грузоподъемностью до 15-ти тонн. <br />
            27 м³ – самые крупные контейнеры с грузоподъемностью до 20-ти тонн
            для крупногабаритных отходов. Идеально подходят для объемного, но не
            тяжелого груза.
            <br />
          </p>
        ),
      },
      {
        id: "aboutService_2",
        title: "Контейнеры для мусора",
        text: (
          <p>
            Часто используются на строительных площадках, при ремонтах, где
            накапливается большое количество мусора. Вывоз мусора контейнером в
            Москве дешевле вывоза машиной с грузчиками. Мусорный контейнер
            устанавливается на вашей территории. При полном заполнении
            контейнера, вы сообщаете нам, и мы приезжаем и забираем контейнер.{" "}
            <br />
            <u>
              Заказать установку контейнера можно позвонив нам или оставив
              заявку на сайте
            </u>
            . <br />
            Вы можете <u>заказать мусорные контейнеры</u> объемом 8, 20 и 27 м³
            в ООО Спецустройство. <br />
            Также мы имеем в распоряжении спецтехнику,{" "}
            <u>самосвалы, мусоровозы</u>. <br />
            При вывозе мусора из многоэтажных зданий, офисных помещений и
            бизнес-центров, предлагаем утилизацию мусора контейнером в Москве и
            Московской области. <br />
            Вы можете заключить договор для получения наших услуг на постоянной
            основе. <br />
            ООО Спецустройство вывозит мусор в любом объеме, с любой территории,
            офиса компании, дома или квартиры. <br />
            Работаем без выходных, строго по графику. <br />
            Выполним поставку автомобиля с вывозом срочно, в случае экстренной
            необходимости. <br />
            Наша компания, ООО Спецустройство предоставляет услуги{" "}
            <u>вывоза контейнером</u> и <u>утилизацию различного вида мусора</u>{" "}
            как юридическим, так и частным лицам в городе Москва и Московской
            области. <br />
            Демонтаж разных конструкций по вывозу твердых коммунальных отходов
            Вывоз КГМ <br />
            Вывоз старой мебели из квартиры <br />
            Уборка территории без вывоза <br />
            Ручную уборку по вывозу <br />
            Ручную погрузку с вывозом <br />
            Контейнер под мусор <br />
            Вывоз строительного мусора <br />
            Газель под мусор <br />
            Предоставляем грузчиков при вывозе <br />
            Вывоз из квартиры <br />
            Установка бункера под мусор, бытовых отходов <br />
            Вывоз твердых бытовых отходов <br />
            Установка контейнера под мусор без вывоза <br />
            Есть все для вывоза газелью <br />
            Работа в мусоре с ПУХТО
            <br />
          </p>
        ),
        textRight: true,
      },
      {
        id: "aboutService_3",
        title: "Как выбрать подрядчика для вывоза мусора контейнерами ",
        text: (
          <>
            <p>
              При выборе подрядчика для вывоза мусора контейнерами рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_5",
    mainBlock__data: {
      title: "Песок, щебень, кирпичный бой, асфальтная крошка с доставкой",
      text: (
        <>
          Песок карьерный <br />
          Песок мытый <br />
          Щебень любой фракции <br />
          Кирпичный бой <br />
          Асфальтная крошка. <br />
          Любые виды грунта с доставкой по Москве и Московской области. <br />
          Вся продукция сертифицирована и прошла экологический контроль. <br />
          от 800 руб. за м³
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Песок, Щебень всех фракций",
        text: (
          <p>
            Компания ООО Спецустройство предлагает песок карьерный, песок мытый,
            щебень всех фракции, кирпичный бой, асфальтная крошка с доставкой по
            Москве и Московской области. <br />
            Все грунты прошли экологический контроль и являются безопасными.{" "}
            <br />
            Мы предлагаем большой выбор грунта любых назначений. <br />
            Натуральные чистые материалы природного происхождения. <br />
            Все виды грунтов в наличии. Мы гарантируем быструю доставку и
            выгрузку вам на объект. <br />
            Благодаря собственному <u>автопарку спецтехники</u> мы оперативно
            доставляем крупные объёмы, любых видов грунта, а также можем делать
            скидки на крупные объемы заказов. <br />
            Для Вашего удобства мы работаем по наличному и безналичному расчету,
            при этом мы предоставляем полный комплект документов. <br />
            Заключаем официальные договоры на доставку и перевозку плодородного
            грунта. <br />
            Условия, сроки прописываются в договоре. <br />
            Остались вопросы? Сколько будет стоить? Как заказать вывоз грунта и
            разработку котлована ответят наши специалисты! <br />
            <u>
              Звоните нам, заказывайте обратный звонок и мы перезвоним или
              отправьте заявку через форму
            </u>
            . <br />
          </p>
        ),
        img: aboutService_img_2_1,
      },
      {
        id: "aboutService_2",
        title: "Как выбрать подрядчика для покупки ПГС и Щебня ",
        text: (
          <>
            <p>
              При выборе подрядчика для покупки ПГС и Щебня рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_6",
    mainBlock__data: {
      title: "Плодородный грунт с доставкой в Москве",
      text: (
        <>
          Чистые, натуральные, материалы, природного происхождения: торф,
          торфо-смеси, почвогрунты. <br />
          Любые виды плодородного грунта с доставкой по Москве и Московской
          области. <br />
          Вся продукция сертифицирована и прошла экологический контроль.
          <br />
          от 800 руб. за м
        </>
      ),
      img: service_1_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Плодородный грунт в москве",
        text: (
          <p>
            Компания ООО Спецустройство предлагает большой выбор плодородных
            грунтов с доставкой по Москве. <br />
            Все предлагаемые нами грунты прошли экологический контроль и могут
            быть использованы для увеличения плодородности почвы. <br />
            Мы предлагаем натуральные чистые материалы природного происхождения,
            такие, как торф, торфо-смеси, пескогрунт, чернозём, и другие
            почвогрунты. <br />
            Все виды грунтов в наличии. <br />
            Мы гарантируем быструю доставку и выгрузку вам на объект. <br />
            Благодаря собственному <u>автопарку спецтехники</u> мы оперативно
            можем доставлять крупные объёмы любых видов грунта, а также можем
            делать скидки на крупные объемы заказов. <br />
            Для Вашего удобства мы работаем по наличному и безналичному расчету,
            при этом мы предоставляем полный комплект документов. <br />
            Заключаем официальные договоры на доставку и перевозку плодородного
            грунта. <br />
            Условия, сроки прописываются в договоре. <br />
            Остались вопросы? Сколько будет стоить? Как заказать вывоз грунта и
            разработку котлована ответят наши специалисты! <br />
            <u>
              Звоните нам, заказывайте обратный звонок и мы перезвоним или
              отправьте заявку через форму.{" "}
            </u>
          </p>
        ),
        img: aboutService_img_2_1,
      },
      {
        id: "aboutService_2",
        title: "Как выбрать подрядчика приобретения плодородного грунта",
        text: (
          <>
            <p>
              При выборе подрядчика для приобретения плодородного грунта
              рекомендуется обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_7",
    mainBlock__data: {
      title: "Разработка котлована",
      text: (
        <>
          Разработка и рытье котлована в Москве и Московской области с вывозом и
          утилизацией грунта в разрешенные полигоны в МО. Все виды оплаты для
          физических и юридических лиц с УНС и НДС. Гарантия сроков в договоре.{" "}
          <br />
          от 450 руб. за м
        </>
      ),
      img: service_7_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Разработка котлована",
        text: (
          <p>
            Один из самых важных этапов в разработке — выполнить рытье
            котлована. <br />
            Рытье котлованов трудный и энергозатратный процесс. <br />
            Мы занимаемся разработкой котлованов и утилизацией грунта и другими
            видами земляных работ. Знаем все нюансы рытья котлованов. Проводим
            все действия по устройству котлована и фундаментов при разных
            земляных сооружениях. Используем только исправную технику. <br />
            Проводим все <u>работы по вывозу и утилизации грунта</u>. <br />
            В нашем штате только профессиональные сотрудники, которые могут
            помочь вам. <br />
            Если что дадут советы как правильно вырыть. Но всегда следуют именно
            указаниям заказчика. Потому что мнение клиента для нас приоритетно.{" "}
            <br />
            Перед рытьем, котлован проектируется, всё документально фиксируется.
            <br />В нашем автопарке есть вся необходимая <u>спецтехника</u>, для
            рытья траншей, котлована и утилизации грунта. <br />В наличии
            экскаваторы с разной глубиной ковша и разного назначения. <br />
            Возможен вызов колесного или гусеничного экскаватора, также
            самосвалы и грузовые машины с контейнерами объемом от 8 до 24 м³.{" "}
            <br />
            Мы поможем вам подобрать технику для вашего участка. Подберем машину
            с контейнером нужного объема. Сами рассчитаем необходимое количество
            куб. м.
            <br />
            Всей техникой управляют опытные специалисты. <br />
            Мы берем в наш штат только профессионалов. Проводим тщательный отбор
            среди кандидатов. Чтобы сохранять оправданную стоимость и качество
            услуг.
            <br />
            Разработка котлованов и рытье траншей по низкой стоимости. Мы
            рассчитываем цену исходя из объема земляных работ, типа грунта и
            количества необходимой техники. <br />
            Чтобы узнать стоимость рытья именно для вашего участка лучше
            позвонить нам или оставить заявку. Наш менеджер позвонит вам,
            уточнит все детали заказа и скажет точную смету.
            <br />
            Разработка нелегкое дело. И мы это понимаем. Подходим к разработке
            котлованов и рытью траншей любых размеров ответственно. Сделаем
            работу качественно, с соблюдением требований безопасности, все по
            указаниям заказчика. Чтобы котлован подходил по всем параметрам для
            нужного фундамента. Важно не просто подъехать и вырыть, но и
            определить как наиболее быстро и эффективно осуществить разработки.
            Чтобы он был вырыт в кратчайшие сроки. Важно произвести укрепление
            стенок котлованов и дно котлована, чтобы проводить дальнейшие
            действия по обустройству фундамента зданий и сооружений.
          </p>
        ),
        img: aboutService_img_2_1,
      },
      {
        id: "aboutService_2",
        title: "Как выбрать подрядчика для разработки котлована ",
        text: (
          <>
            <p>
              При выборе подрядчика для разработки котлована рекомендуется
              обращать внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_8",
    mainBlock__data: {
      title: "Вывоз снега",
      text: (
        <>
          Под ключ на специальные разрешенные полигоны в Москве и Московской
          области по низкой цене <br />
          Выезд на объект за 1 час с пропуском до Садового кольца <br />
          Сроки и стоимость фиксируем в договоре <br />
          Все виды оплат для физических и юридических лиц с НДС <br />
          от 297 руб. за м³
        </>
      ),
      img: service_8_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Вывоз снега",
        text: (
          <p>
            Вместе с зимней сказочной атмосферой и новогодними праздниками в
            столицу приходят и множество осадков в виде снега. Снег засыпает
            улицы и крыши домов, мешает проезду автомобилей, проходу людей. Мало
            очистить дорогу от снега, его нужно убрать. В противном случае
            весной в зонах скопления снега будут большие лужи, а некоторые горы
            снега не таят вплоть до конца апреля. <br />
            Во избежание проблем нужно утилизировать снег. Для этого лучше
            обратиться к проверенным перевозчикам и организациям, которые имеют
            большой опыт и перевезли уже не одну тонну снежных отходов в
            снегоплавильные пункты. <br />
            Мы много лет занимаемся специализированными грузоперевозками. <br />
            Наша специализация:{" "}
            <u>
              уборка снега с чисткой территории от снега, погрузка и вывоз снега
              на снежные полигоны и снегоплавильные станции
            </u>
            . <br />
            Мы, ООО Спецустройство предоставляем свою спецтехнику для решения
            этих задач. <br />
            Мы занимаемся <u>уборкой снега</u> с вывозом с офисных площадок и
            частных домов. Там где габаритные машины не могут развернуться, мы
            используем контейнеры малого объема и мини погрузчики. <br />
            Зимой от снежных завалов используем трактора и экскаваторы погрузчик
            JCB и других марок. <br />В штате сильные и опытные грузчики.
            Собственный автопарк,
            <u>экскаваторы</u> и <u>грузовые автомобили для перевозки снега</u>.{" "}
            <br />
            Низкая стоимость вывоза снега в Москве и официальный договор на
            проведение всех видов работ, на утилизацию снега. <br />
            Убираем снежные массы оперативно с территории заказчика,
            осуществляем доставку на полигоны специальных предприятий. <br />
            Гарантируем качество. Работаем во всех районах Москвы. Предоставляем
            услуги круглосуточно, без выходных. Заключаем договор. <br />
            По заказу наши сотрудники сами уберут снег с территории и погрузят в
            контейнер 8 – 24 м³. Для небольших объемов работ используем
            небольшие объемы контейнеров – 8 м.куб. Рассчитать объем контейнера
            помогут наши сотрудники. Укажите площадь и характер поверхности.{" "}
            <br />
            Точную стоимость работ определит менеджер. Стоимость работ зависит
            не только от объема техники, но и от необходимости утилизации.{" "}
            <br />
            При заказе, уборки и вывоза снега, вы получаете опытных и сильных
            специалистов, которые имеют всё необходимое оборудование. <br />
            Вам не нужно искать людей и оборудование для погрузки снега.{" "}
            <u>Наши сотрудники</u> быстро и качественно произведут очистку
            территории от снега после снегопадов, удалят наледь. <br />
            На небольших площадках и во дворах, порой лучше обойтись уборкой,
            используя ручную погрузку. <br />
            Для большой площади механизированная уборка. Быстрое и качественное
            обслуживание любых заказов. <br />
            Оптимальная цена за 1 м³. <br />
            После уборки снега, важно знать куда его вывозить. Для этого у нас
            заключены договоры с снегоплавильными пунктами Москвы и области.{" "}
            <br />
            Мы занимаемся уборкой и утилизацией снега несколько лет. <br />
            ООО Спецустройство поможет при вывозе любых объемов от 1 м³ до
            нескольких десятков или сотен. Пришлем для транспортировки снега
            несколько машин камазов с разным объемом. <br />
            Цена услуги с погрузкой вывоз снега от 1 м³ зависит от рационального
            использования автотехники большой грузоподъёмности. <br />
            Наиболее <u>удобные и мощные самосвалы</u>, в которые снежную массу
            грузят гораздо выше кузова, что позволяет за 1 рейс вывезти больше
            снега. Осуществляем вывоз контейнерами разного объема от 1 куб.м.{" "}
            <br />
            Максимальный объем контейнера в нашем арсенале – 24 м³. Работаем во
            всех районах Москвы.
          </p>
        ),
      },
      {
        id: "aboutService_2",
        title: "Как выбрать подрядчика для вывоза снега",
        text: (
          <>
            <p>
              При выборе подрядчика для вывоза снега рекомендуется обращать
              внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: "service_9",
    mainBlock__data: {
      title: "Талоны на утилизацию грунта, мусора",
      text: (
        <>
          У нас можно заказать услуги утилизации бытовых и промышленных отходов
          и оформлению всех необходимых документов, связанных с их перевозкой.{" "}
          <br />
          Утилизация на разрешенных полигонах Москвы и Московской области.
          <br />
          от 40 руб. за м³ <br />
          <br />
          <b>Талоны + договор с полигоном от 40 руб/м3</b>
        </>
      ),
      img: service_9_mainBlock,
    },
    service__data: [
      {
        id: "aboutService_1",
        title: "Для чего и зачем нужны талоны?",
        text: (
          <>
            <p>
              Клиенту выдаются специальные талоны на вывоз мусора, для
              подтверждения, что мусор вывезли на лицензионную свалку. <br />
              ООО Спецустройство вывозит любой тип мусора, любые грунты, снег.{" "}
              <br />
              Для этого заказчикам необходимо приобрести талоны для утилизации.{" "}
              <br />
              Услуга вывоза мусора, осуществляется <u>самосвалами</u>.
            </p>
            <p>
              Мы предлагаем: <br />
              Мы подберём полигон, место перевалки отходов; <br />
              выдаем вам утилизационные талоны для <u>вывоза грунта</u> и прочих
              материалов; <br />
              оформляем полный пакет документации для предоставления отчетов о
              количестве вывезенных отходов.
              <br />
              ООО Спецустройство предлагает вам получить талоны на ТБО (твердые
              бытовые отходы), строительный мусор, грунт и т. д. <br />
              Мы работаем как с крупными компаниями, так и с частными лицами,
              купить талоны на утилизацию можно в любом количестве. <br />
              Своевременно оформленные талоны на размещение мусора, на
              лицензионной свалке, избавят вас от лишней головной боли. <br />
              Это является гарантией, что услуги будут оказаны в рамках
              правового поля. <br />
              ООО Спецустройство работает с крупными полигонами, комплексами по
              мусоропереработке. <br />
              Мусор распределяется на 5 классов опасности. <br />
              От 1 класса (крайне опасного) до 5 класса — наименее опасного для
              экологической обстановки и людей. <br />У нас, вы можете получить
              талоны соответствующие классу опасности 1-5 ваших отходов.
            </p>
          </>
        ),
      },
      {
        id: "aboutService_2",
        title: "Этапы выдачи талонов",
        text: (
          <>
            <p>
              Вы заключаете договор с нашей компанией. <br />
              Согласовываете график или заказываете единоразовую услугу. <br />
              Вы можете воспользоваться <u>нашими самосвалами</u> или
              использовать собственный транспорт. <br />
              Заранее необходимо рассчитать объем отходов. <br />
              В зависимости от реальных нужд — приобретать талоны. <br />
              Все документы имеют нумерацию и серию, в них указывается объем,
              вес отходов и их тип. <br />
              Если у вас имеются талоны на снег, вы можете вывезти только снег.
            </p>
            <p>
              ООО Спецустройство предоставляет квитки индивидуального образца с
              несколькими степенями защиты, исключая подделки. <br />
              После размещения отходов на полигоне или свалке заказчик получает
              специальный корешок — часть купона, уведомляющий о том, что
              процедура утилизации выполнена. <br />
              Талоны со свалки необходимо хранить для отчетности перед
              контролирующими органами. <br />
              Талоны на утилизацию цена (стоимость) может варьироваться, она
              зависит от вида отхода и места его размещения.
            </p>
          </>
        ),
      },
      {
        id: "aboutService_3",
        title: "Как выбрать подрядчика для получения талонов",
        text: (
          <>
            <p>
              При выборе подрядчика для получения талонов рекомендуется обращать
              внимание на следующие факторы:
            </p>
            <p>
              <b>Опыт и квалификация:</b> Подрядчик должен иметь достаточный
              опыт и квалификацию для выполнения демонтажных работ различного
              уровня сложности.
            </p>
            <p>
              <b>Лицензии и разрешения:</b> Убедитесь, что подрядчик имеет все
              необходимые лицензии и разрешения для проведения демонтажных работ
              в соответствии с законодательством.
            </p>
            <p>
              <b>Репутация и отзывы:</b> Исследуйте репутацию подрядчика,
              прочитайте отзывы клиентов и оцените его профессионализм и
              надежность.
            </p>
            <p>
              <b>Безопасность:</b> Узнайте о политике безопасности подрядчика и
              какие меры он предпринимает для обеспечения безопасности на
              рабочих местах.
            </p>
            <p>
              Мы, компания "СУ", обладаем богатым опытом и квалификацией в
              области демонтажа зданий и сооружений. Наша команда профессионалов
              обеспечит безопасное и эффективное выполнение всех демонтажных
              работ. Мы гарантируем высокое качество услуг, соответствие
              требованиям и соблюдение сроков.
            </p>
            <p>
              Если вам требуется демонтаж здания или сооружения, мы рады
              предложить наши услуги. Свяжитесь с нами, чтобы узнать больше
              информации и оформить заказ.
            </p>
          </>
        ),
      },
    ],
  },
];
