import React from "react";

import "./Letters.scss";

import ContentBlockGrey from "../../wrappers/ContentBlockGrey/ContentBlockGrey";

import letterImg1 from "../../../assets/letterImg1.jpg";

const letters_data = [
  {
    id: "letter1",
    title: "ООО «НикНерудСбыт»",
    text: (
      <>
        Уважаемый Ильдар Шамилович , искренне благодарю Вас и Ваш коллектив за качественное оказание услуг по вывозу грунта с наших строительных объектов.                                                            Своевременная подача автотранспорта, профессионализм   Ваших сотрудников позволили   в короткие сроки произвести плановые работы на участках и завершить данные этапы строительства досрочно. Также хочется отметить демократичность цены предоставленных услуг. Надеемся на дальнейшее сотрудничество.
      </>
    ),
    img: letterImg1,
    document: true,
    img_alt: "letterImg1",
    noLink: true,
  },
];

const Letters = () => {
  return (
    <>
      <div className="letters__title contentBlock__title">
        Благодарственные письма
      </div>
      <div className="lettersContentWrapper">
        {letters_data.map((item) => {
          return <ContentBlockGrey key={item.id} {...item} />;
        })}
      </div>
    </>
  );
};

export default Letters;
