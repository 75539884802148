import React from "react";

import { useEffect } from "react";

import "./MainPage.scss";

import HeaderUI from "../../components/ui/HeaderUI/HeaderUI";
import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import OurProgress from "../../components/content_blocks/OurProgress/OurProgress";
import AboutShort from "../../components/content_blocks/AboutShort/AboutShort";
import Services from "../../components/content_blocks/Services/Services";
import OurProjects from "../../components/content_blocks/OurProjects/OurProjects";
import WorkSteps from "../../components/content_blocks/WorkSteps/WorkSteps";
// import Reviews from "../../components/content_blocks/Reviews/Reviews";
import Clients from "../../components/content_blocks/Clients/Clients";
import Price from "../../components/content_blocks/Price/Price";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
// import Articles from "../../components/content_blocks/Articles/Articles";
import Tags from "../../components/content_blocks/Tags/Tags";
import Contacts from "../../components/content_blocks/Contacts/Contacts";
import Footer from "../../components/ui/Footer/Footer";
import NavSlider from "../../components/ui/NavSlider/NavSlider";
import PopUp from "../../components/modal/PopUp/PopUp";

import ObserverWrap from "../../components/wrappers/ObserverWrap/ObserverWrap";

import mainBlock from "../../assets/mainBlock.jpg";

const price_data = [
  {
    id: "1",
    service: "МАЗ 650129-8420",
    price: "ОТ 12000 Р",
    unit: "СМЕНА",
  },
  {
    id: "2",
    service: "КАМАЗ К5042 65801-Т5",
    price: "ОТ 12000 Р",
    unit: "СМЕНА",
  },
  {
    id: "3",
    service: "МАЗ 650128-8520",
    price: "ОТ 12000 Р",
    unit: "СМЕНА",
  },
  {
    id: "4",
    service: "КОНТЕЙНЕРЫ 8.20. 27м3",
    price: "ОТ 8000 Р",
    unit: "СМЕНА",
  },
  {
    id: "5",
    service: "АРЕНДА САМОСВАЛА ",
    price: "ОТ 1200 Р ",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
  {
    id: "6",
    service: "Аренда экскаватора погрузчика",
    price: "ОТ 12000 Р",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
  {
    id: "7",
    service: "JCB 3CX",
    price: "От 15 000 р",
    unit: "СМЕНА",
  },
  {
    id: "8",
    service: "JCB 4CX",
    price: "От 15 000 р",
    unit: "СМЕНА",
  },
  {
    id: "9",
    service: "Аренда гусеничного экскаватора",
    price: "ОТ 15000 Р",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
  {
    id: "10",
    service: "JCB 200",
    price: "ОТ 15000 Р",
    unit: "СМЕНА",
  },
  {
    id: "11",
    service: "Экскаватор Hitachi-ZX-330",
    price: "ОТ 15000 Р",
    unit: "СМЕНА",
  },
  {
    id: "12",
    service: "Экскаватор Hyundai R290",
    price: "ОТ 15000 Р",
    unit: "СМЕНА",
  },
  {
    id: "13",
    service: "Аренда колёсного экскаватора",
    price: "ОТ 1500 Р",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
  {
    id: "14",
    service: "Cat M315D2, Cat M317D2, Cat M322D2",
    price: "ОТ 1500 Р",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
  {
    id: "15",
    service: "Catepillar M318D",
    price: "ОТ 1500 Р",
    unit: (
      <>
        м<sup>3</sup>
      </>
    ),
  },
];

const tags_data = [
  {
    id: "tag_1",
    tag: "Демонтаж зданий под ключ",
  },
  {
    id: "tag_2",
    tag: "Вывоз мусора",
  },
  {
    id: "tag_3",
    tag: "Вывоз песка",
  },
  {
    id: "tag_4",
    tag: "Аренда спецтехники",
  },
  {
    id: "tag_5",
    tag: "Земляные работы",
  },
  {
    id: "tag_6",
    tag: "Вывоз снега",
  },
  {
    id: "tag_7",
    tag: "Копка котлованов",
  },
  {
    id: "tag_8",
    tag: "Талоны на утилизацию",
  },
  {
    id: "tag_9",
    tag: "Подготовка строительных участков",
  },
  {
    id: "tag_10",
    tag: "Система ГЛОНАС",
  },
];

const contentProps = [
  {
    id: "n1",
    link: "Почему мы?",
    path: "OurProgress",
    className: "ourProgress",
    component: <OurProgress />,
  },
  {
    id: "n2",
    link: "О нас",
    path: "AboutShort",
    className: "aboutShort",
    component: <AboutShort />,
  },
  {
    id: "n3",
    link: "Основные услуги",
    path: "Services",
    className: "services",
    component: <Services />,
  },
  {
    id: "n4",
    link: "Примеры работ",
    path: "OurProjects",
    className: "ourProjects",
    component: <OurProjects />,
  },
  {
    id: "n5",
    link: "Этапы работ",
    path: "WorkSteps",
    className: "workSteps",
    component: <WorkSteps />,
  },
  // {
  //   id: "n6",
  //   link: "Отзывы клиентов",
  //   path: "Reviews",
  //   className: "reviews",
  //   component: <Reviews />,
  // },
  {
    id: "n7",
    link: "Наши клиенты",
    path: "Clients",
    className: "clients",
    component: <Clients />,
  },
  {
    id: "n8",
    link: "Цены на аренду спецтехники",
    path: "Price",
    className: "price",
    component: (
      <Price title="Цены на аренду спецтехники в Москве" data={price_data} />
    ),
  },
  {
    id: "n9",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
  // {
  //   id: "n10",
  //   link: "Блог",
  //   path: "Articles",
  //   className: "articles",
  //   component: <Articles />,
  // },
  {
    id: "n11",
    className: "tags",
    component: <Tags key="Tags" data={tags_data}/>,
  },
  {
    id: "n12",
    link: "Контакты",
    path: "Contacts",
    className: "contacts",
    component: <Contacts />,
  },
];

const mainBlock__data = {
  title: "Земляные работы в Московском регионе с погрузкой и утилизацией",
  text: (
    <ul>
      <li>Подготовка территорий к застройке под ключ</li>
      <li>Снос зданий и сооружений</li>
      <li>Земляные работы под ключ</li>
      <li>Вывоз ОССиГ и ТБО (отходов I-V класса , в том числе грунтов, строймусора, медицинских отходов)</li>
      <li>Контейнеры 8, 20, 27 м3</li>
      <li>Доставка песка, щебня и прочих материалов</li>
      <li>Вывоз снега</li>
      <li>Аренда самосвалов, тракторов, прочей спецтехники , бытовок, спецоборудования.</li>
    </ul>
  ),
  img: mainBlock,
};



const Main = () => {
  useEffect(() => {
  });

  return (
    <>
      <HeaderUI />
      <div className="content">
        <MainBlock {...mainBlock__data} />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <div className="contentCollum">
            {contentProps.map((content) => {
              if (content.className === "tags") {
                return content.component;
              } else {
                return (
                  <ObserverWrap
                    key={content.path}
                    className={content.className}
                    id={content.path}
                  >
                    {content.component}
                  </ObserverWrap>
                );
              }
            })}
          </div>
        </div>
      </div>
      <PopUp />
      <Footer />
    </>
  );
};

export default Main;
