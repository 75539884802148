import React from "react";

import AboutServiceContentBlock from "../AboutService/AboutServiceContentBlock/AboutServiceContentBlock";

import "./RentTech.scss";

const rentTech_data = [
  {
    id: "data1",
    title: "Преимущества аренды самосвала с водителем",
    text: (
      <>
        Возможность не покупать собственный спецтранспорт — оформите договор
        аренды и производите изыскательные, землеустроительные, строительные
        работы; отсутствие необходимости содержания кадровых водителей,
        персонала, проводящего ТО автомобилей; снижение расходов за счет
        отсутствия трат на сервис, транспортные налоги; круглосуточные услуги
        аренды — оформить заявку на нашем сайте можно 24 часа в сутки в любой
        день недели; наши опытные водители всегда знают дорожную обстановку в
        столице и по области; у нас в наличии необходимые специальные
        разрешения, пропуски для подачи транспорта в любую часть Москвы Если вам
        нужно транспортировать грунт, вывезти строительные отходы, перевезти
        тяжелые грузы, в ООО Спецустройство можно взять в аренду самосвал любой
        грузоподъемности: <br />
        до 2 т; <br />
        2-6 т; <br />
        7-14 т; <br />
        более 14 т<br />
      </>
    ),
  },
  {
    id: "data2",
    title: "Как выбрать модель самосвала",
    text: (
      <>
        Выбор техники определяется конкретными задачами. При этом учитываются
        условия: назначение, требуемый тип разгрузки, проходимость. Вы можете
        взять как дорожный, так и внедорожный грузовик в аренду. Самосвалы
        эффективно транспортируют скальные, навалочные, сыпучие грузы.
        Особенность спецтехники заключается в возможности без каких-либо усилий
        высыпать груз, наклоняя до 45-55 градусов механизированный кузов. Аренда
        самосвала с нормой загрузки до 20 тонн актуальна, когда необходим
        огромный объем кузова. Такой транспорт выбирают, например, когда нужно
        вывезти снег. Эти машины незаменимы в строительстве и готовы работать в
        любых метеоусловиях. Аренда самосвала — 20 кубов позволяет перевезти за
        одну поездку большой объем сыпучих грузов. Одни единица такой надежной,
        неприхотливой техники заменяет несколько средних грузовых машин.
        Выгодная аренда самосвала 20 м³ позволяет, не покупать дорогостоящий
        автомобиль, и решать производственные задачи. Заказывая автомобиль,
        самостоятельно или с помощью нашего менеджера определите его задачи,
        дистанцию выгрузки, угол наклона, способ разгрузки (1-сторонняя назад
        или на боковую сторону, 2-сторонняя на боковые стороны, 3- сторонняя на
        боковые стороны и задняя часть). <br />
        На сегодняшний день, наиболее популярны аренда КАМАЗа или МАЗа с
        гидравлической трансмиссией. Гидравлика автоматизирует и ускоряет
        процесс разгрузки, экономит время. <br />
        Выбирая автомобиль, оцените: <br />
        базовое шасси; мощность мотора; колесную базу; объем платформы;
        грузоподъемность. подсчитайте километраж, массу груза.
      </>
    ),
  },
  {
    id: "data3",
    title: "Из чего состоит цена на аренду самосвала?",
    text: (
      <>
        Арендная плата за использование импортной спецтехники выше, чем
        отечественной, поскольку обслуживание и поддержание в хорошем
        техническом состоянии, замена изношенных запчастей, узлов, агрегатов
        зарубежной техники стоит дороже. Чем дольше вы используете спецтехнику,
        тем ниже арендная плата. Примерный расчет цены аренды можно получить,
        разделив стоимость техники на 12 мес. Такой формулой удобно пользоваться
        при определении стоимости краткосрочной аренды спецтехники — на час,
        сутки или недели. <br />
        На цену влияют следующие факторы: <br />
        сроки аренды; расстояние до стройплощадки; услуги оператора при заказе
        спецтехники с экипажем; аренда вспомогательной техники (навесное
        оборудование, и т.п.); грузоподъемность; наличие специальных разрешений;
        вид самосвала, его технические параметры. Некоторая часть арендной платы
        покрывает затраты на ГСМ, амортизацию механизмов, на содержание и ТО.
        Конечная цена определяется степенью загруженности техники. <br />
        Цены на аренду самосвалов в ООО Спецустройство — невысоки. Затраты на
        оплату работы водителя наша компания берет на себя. Мы поможем вам
        выбрать наиболее подходящий транспорт для ваших работ. Любые вопросы, вы
        можете задать, связавшись с нашим менеджером.
      </>
    ),
  },
  {
    id: "data4",
    title: "Дополнительные преимущества",
    text: (
      <>
        Лицензия на все виды деятельности Юридически чистая работа с полным
        пакетом документов Мы – владельцы парка спецтехники Только исправная
        техника Опытные водители Работаем круглосуточно Допуск во все районы
        Москвы и Московской области <br />
        ОСТАВЬТЕ ЗАЯВКУ, МЫ ПЕРЕЗВОНИМ
      </>
    ),
  },
];

const RentTech = () => {
  return (
    <>
      <div className="rentTech__title contentBlock__title">
        Аренда спецтехники
      </div>
      <div className="rentTechServiceContentWrapper">
        {rentTech_data.map((block) => {
          return <AboutServiceContentBlock {...block} key={block.id} />;
        })}
      </div>
    </>
  );
};

export default RentTech;
