import React, { useState } from "react";

import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger";


import "./OurProgress.scss";

const OurProgress = () => {
  const [startCountTop, setStartCountTop] = useState(false);
  const [startCountBot, setStartCountBot] = useState(false);

  return (
    <>
      <div className="ourProgressTop">
        <div className="ourProgress__title contentBlock__title">
          За одни сутки мы сможем
        </div>
        <ScrollTrigger onEnter={() => setStartCountTop(true)}>
        <div className="ourProgressContent">
          <div className="ourProgressBlock">
            <div className="ourProgress__number">
                {
                  startCountTop && <CountUp start={0} end={4000} duration={2} delay={0} separator=""/>
                }
              м<sup>3</sup>
            </div>
            <div className="ourProgress__text">Вывезти грунта</div>
          </div>
          <div className="vertLine vertLine-mobile"></div>
          <div className="ourProgressBlock">
            <div className="ourProgress__number">
                {
                  startCountTop && <CountUp start={0} end={40} duration={2} delay={0} separator=""/>
                }
            </div>
            <div className="ourProgress__text">Использовать грузовиков</div>
          </div>
          <div className="vertLine vertLine-mobile"></div>

          <div className="ourProgressBlock">
            <div className="ourProgress__number">
                {
                  startCountTop && <CountUp start={0} end={120} duration={2} delay={0} separator=""/>
                }
            </div>
            <div className="ourProgress__text">Совершить рейсов</div>
          </div>
          <div className="vertLine vertLine-mobile"></div>
        </div>
        </ScrollTrigger>
      </div>
      <div className="ourProgressBot">
        <div className="ourProgress__title contentBlock__title">
          Наши достижения в цифрах
        </div>
        <ScrollTrigger onEnter={() => setStartCountBot(true)}>

        <div className="ourProgressContent">
          <div className="ourProgressBlock">
            <div className="ourProgress__number">{">"}
                {
                  startCountBot && <CountUp start={0} end={7} duration={2} delay={0} separator=""/>
                }
            </div>
            <div className="ourProgress__text">лет опыта работы</div>
          </div>
          <div className="vertLine vertLine-mobile"></div>
          <div className="ourProgressBlock">
            <div className="ourProgress__number">{">"}
              {
                startCountBot && <CountUp start={0} end={300} duration={2} delay={0} separator=""/>
              }
            </div>
            <div className="ourProgress__text">реализованных проектов</div>
          </div>
          <div className="vertLine vertLine-mobile"></div>

          <div className="ourProgressBlock">
            <div className="ourProgress__number">{">"}
              {
                startCountBot && <CountUp start={0} end={700} duration={2} delay={0} separator=""/>
              }
            </div>
            <div className="ourProgress__text">довольных клиентов</div>
          </div>
        </div>
        </ScrollTrigger>
      </div>
    </>
  );
};

export default OurProgress;
