import React from "react";

import "./AboutFull.scss";

const AboutFull = () => {
  return (
    <>
      <div className="aboutFull__title contentBlock__title">О компании</div>
      <div className="aboutFullContentWrapper">
        <div className="aboutFullInfoWrapper">
          Наши преимущества: <br />
          Мы команда профессионалов: опытные инженеры, механики, операторы,
          водители и рабочие. Нашу спецтехнику можно арендовать вместе с
          экипажем и рабочими на нужный период времени, а также по необходимости
          комплектовать навесным оборудованием разного назначения. Мы предлагаем
          конкурентные цены на наши услуги. Возможна оплата за наличный
          или безналичный расчёт. При заключении договора на длительную аренду —
          возможна рассрочка платежа. <br /> Наше техника и оборудование,
          проходят своевременное ТО, строительная техника всегда в рабочем
          состоянии. В случае поломки на объекте, мы вам сразу предложим
          аналогичную замену. Мы предоставляем оперативное и профессиональное
          обслуживание. Бесплатная консультация и помощь при выборе
          спецтехники.
          <br />
          Вся наша техника оборудована для работы по системе АИС ОССиГ , сигналы передаются в РНИС и Сапкутр, все сотрудники компании оснащены телефонами с приложением «Мобильный КПТС» 
        </div>
      </div>
    </>
  );
};

export default AboutFull;
