import React from "react";

import "./Tags.scss";

const Tags = ({data}) => {
  return (
    <div className="tags contentBlock">
      {data.map((item) => {
        return (
          <div className="tags__block" key={item.id}>
            {item.tag}
          </div>
        );
      })}
    </div>
  );
};

export default Tags;
