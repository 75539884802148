import React from "react";

import ContentBlockGrey from "../../wrappers/ContentBlockGrey/ContentBlockGrey";

import "./Vacations.scss";

import profile_img from "../../../assets/profile.png";

const vacations_data = [
  {
    id: "vacation1",
    title: "Водитель Самосвала",
    text: (
      <>
        Условия: <br />
        Работа в Москве и/или московской области. Основной вид деятельности
        компании перевозка грунта, снега и перевозка сыпучих грузов. Оформление
        по ТК РФ. Возможна вахта. Есть проживание. Зар.плата сдельная (в среднем
        от 60 000 до 140 000 руб). Работа на самосвале МАЗ, КАМАЗ 5511,
        КАМАЗ-6520. <br />
        Требования: <br />
        <ul>
          <li>Опыт работы на спецтехники подобного класса.</li>
          <li>Водительские права категории C.</li>
          <li>Хорошее знание техники.</li>
          <li>Отсутствие судимости.</li>
        </ul>
      </>
    ),
  },
  {
    id: "vacation2",
    title: "Менеджер отдела продаж",
    middle: true,
    text: (
      <>
        Условия: <br />В компанию, занимающуюся арендой спецтехники, требуется
        энергичный и мотивированный менеджер по продажам. Успешному кандидату
        предоставляется возможность работать на процент от каждой успешно
        закрытой сделки. Если ты обладаешь превосходными навыками продаж, умеешь
        находить общий язык с клиентами и хочешь добиться высоких результатов,
        присоединяйся к нашей команде и помогай нам развиваться и расти вместе!
      </>
    ),
  },
  {
    id: "vacation2",
    title: "Маркетинг менеджер",
    text: (
      <>
        Ищем опытного и целеустремленного менеджера по рекламе, который будет
        работать на процент от каждой успешной сделки. Ты будешь ответственен за
        привлечение новых клиентов и создание эффективных рекламных кампаний. У
        нас тебя ждет возможность зарабатывать хороший и стабильный доход на
        основе своих результатов. Если ты активный, творческий и готов брать на
        себя ответственность за развитие нашей компании, присоединяйся к нам и
        помогай нам достигать новых высот в рекламной индустрии!
      </>
    ),
  },
];

const Vacations = () => {
  return (
    <>
      <>
        <div className="vacations__title contentBlock__title">
          Список основных услуг
        </div>
        <div className="vacationsContent">
          {vacations_data.map((item) => {
            return <ContentBlockGrey {...item} key={item.id} />;
          })}
        </div>
      </>
    </>
  );
};

export default Vacations;
