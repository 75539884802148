import React from "react";

import "./BlogPage.scss";


import HeaderUI from "../../components/ui/HeaderUI/HeaderUI";
import Footer from "../../components/ui/Footer/Footer";
import TagsSmall from "../../components/reusable/TagsSmall/TagsSmall";
import BlogSlider from "../../components/content_blocks/BlogSlider/BlogSlider";

import placeholder from "../../assets/aboutCompany.jpg"
import arrowLeft from "../../assets/arrow-left.svg"
import arrowRight from "../../assets/arrow-right.svg"

import { Link } from "react-router-dom";

const tagsSmall_data = [
    {
        id: 1,
        text: "аренда спецтехники",
    },
    {
        id: 2,
        text: "подготовка к строительству",
    },
    {
        id: 3,
        text: "строительные работы",
    },
    {
        id: 4,
        text: "вывоз мусора",
    },
]

const Blog = () => {

    return (
        <>
            <HeaderUI />
            <div className="mainBlock mainBlock-blog">
                <div className="mainBlockLeftWrapper">
                <div>
                    <Link className="mainBlock__link" to="/"><img src={arrowLeft} alt="arrow left" />Вернуться на главную</Link>
                    <div className="mainBlock__title mainBlock__title-blog">
                        Блог
                    </div>
                    {/* <div className="mainBlock__date">05.08.2024</div> */}
                </div>
                    <div className="mainBlockBottomWrapper">
                        <div className="mainBlock__text">
                            <div>
                                Здесь собраны статьи, полезная информация и ответы на частые вопросы, связанные с предоставляемыми услугами.  
                            </div>
                            <TagsSmall data={tagsSmall_data}/>
                        </div>                    
                    </div>
            </div>
            <img className="mainBlock__img" src={placeholder} alt="placeholder" />
            </div>
            <BlogSlider />
            <Footer />
        </>
    )
}

export default Blog;