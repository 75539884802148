import React from 'react'

import { useState, useEffect } from "react";
import blogData from '../../../api/blogData';
import BlogBlock from '../../reusable/BlogBlock/BlogBlock';


import "./BlogSlider.scss"

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import { Grid, Pagination } from 'swiper/modules';



const BlogSlider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      };

    const slideCounter = () => {
        if (windowWidth > 1056) {
            return 3;
          }
         
        if (windowWidth > 600) {
            return 2;
        }
        return 1;
    }

    const rowsCounter =() => {
        if (windowWidth > 1056) {
            return 2;
          }
         
        if (windowWidth > 600) {
            return 3;
        }
        return 4;
    }

    return (
    <div className='blogSlider'>
        <Swiper
            slidesPerView={slideCounter()}
            grid={{
                rows: rowsCounter(),
            }}
            spaceBetween={0}
            pagination={pagination}
            modules={[Grid, Pagination]}
            className="mySwiper"
            >
            {
                blogData.map((item) => {
                    return (
                        <SwiperSlide key={item.id}>
                            <BlogBlock data={item} />       
                        </SwiperSlide>
                    );
                })
            }
        </Swiper>
    
    </div>
  )
}

export default BlogSlider